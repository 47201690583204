import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqSpvw10251GetDetail } from 'src/app/request/req-spvw10251-get-detail';
import { Spvw10251GetDetailDto, RspSpvw10251GetDetail } from 'src/app/response/rsp-spvw10251-get-detail';
import { AccessInfo } from 'src/app/page/spvw10251/spvw10251.component';
import { MatPaginator } from '@angular/material/paginator';

class Spvw10251DetailDialogConstant{
  public static readonly MESSAGESEARCHING = '検索中・・・';
  public static readonly EMPTY_STRING = '';
  public static readonly REDBLACKFLG_02 = '02';
}

interface Spvw10251TableColDef extends TableColumnDef {
  isNumber: boolean;
}

@Component({
  selector: 'app-spvw10251-detail-dialog',
  templateUrl: './spvw10251-detail-dialog.component.html',
  styleUrls: ['./spvw10251-detail-dialog.component.css']
})
export class Spvw10251DetailDialogComponent implements OnInit {

  public columnId: string[] = ["productCd","productNm","orderLotQty","orderCaseQty","orderRequestQty","orderPrice","orderAmt"]

  public columnDefsItems: Spvw10251TableColDef[] = [
    {columnId: "productCd", header: "商品コード", width: 150, align: "left", isNumber: false},
    {columnId: "productNm", header: "商品名称", width: 120, align: "left", isNumber: false},
    {columnId: "orderLotQty", header: "入数", width: 80, align: "right", isNumber: true},
    {columnId: "orderCaseQty", header: "ケース", width: 120, align: "right", isNumber: true},
    {columnId: "orderRequestQty", header: "発注数", width: 120, align: "right", isNumber: true},
    {columnId: "orderPrice", header: "発注単価", width: 150, align: "right", isNumber: true},
    {columnId: "orderAmt", header: "発注金額", width: 120, align: "right", isNumber: true}
  ];

  public itemTableWidth: any;
  public itemRecordCount: number = 0;
  public itemsShow: Spvw10251GetDetailDto[] = [];
  public items: Spvw10251GetDetailDto[] = [];
  public outStoreNm: string;
  public inStoreNm: string;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spvw10251DetailDialogComponent>,
    public httpBasic: HttpBasicService,
    @Inject(MAT_DIALOG_DATA) public data: {orderSlipNo: string, accessInfo: AccessInfo},
    public commonService: CommonService) { }

  ngOnInit() {
    this.calcTableWidth();
    this.getDetailPrc();
  }

  calcTableWidth() {
    let width = 1
    for (let colDef of this.columnDefsItems) {
      width = width + colDef.width + 1 + 8;
    }
    this.itemTableWidth = {"width": "" + width + "px"};
  }

  getDetailPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, Spvw10251DetailDialogConstant.MESSAGESEARCHING);
    const request: ReqSpvw10251GetDetail = {
      orderSlipNo: this.data.orderSlipNo,
      access: this.data.accessInfo
    } 
    const subsc = this.httpBasic.getDetailSpvw10251(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpvw10251GetDetail) {
    this.commonService.closeSpinner();
    if(this.httpBasic.handleAppError(response)){
      return;
    }

    this.itemRecordCount = response.results.length ?? 0;
    response.results.forEach((item: Spvw10251GetDetailDto) => {
      this.items.push(item)
    })
    this.pageChanged()
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0){
      this.pageChanged();
    }
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
  }
}
