import { formatDate } from "@angular/common";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { TableColumnDef } from "src/app/common/table-column-def";
import { AccessInfo } from "src/app/page/spmt10311/spmt10311.component";
import { ReqGetDropdownListOfUser } from "src/app/request/req-get-dropdown-list-of-user";
import { ReqSpmt10311GetSlipNoList } from "src/app/request/req-spmt10311-get-slipno-list";
import { DropdownListOfUserDto, RspGetDropdownListOfUser } from "src/app/response/rsp-get-dropdown-list-of-user";
import { RspSpmt10311GetSlipNoList, SlipNoDto } from "src/app/response/rsp-spmt10311-get-slipno-list";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";
import { MI100001, MI200003 } from "src/app/common/const-message-id";
import { MessageService } from "src/app/service/message.service";
import { ReqPageDto } from "src/app/request/req-page-dto";

class Spmt10311SlipNoListConstant {
  public static readonly MAXITEMS: number = 1000;
  public static readonly DATE_FORMAT = 'yyyy-MM-dd';
  public static readonly EMPTY_STRING = '';
  public static readonly MESSAGE_LOADING = '検索中・・・';
  public static readonly REQUESTERCD_CTRL = 'requesterCd';
  public static readonly APPROVERCD_CTRL = 'approverCd';
  public static readonly SLIP_CREATE_DATE_FROM_CTRL = 'slipCreateDateFrom';
  public static readonly SLIP_CREATE_DATE_TO_CTRL = 'slipCreateDateTo';
  public static readonly FPMT1031 = 'FPMT1031';
  public static readonly FPMT1036 = 'FPMT1036';
  public static readonly ZERO = 0;
  public static readonly LIMIT_RECORD = 1001;
}

@Component({
  selector: "app-spmt10311-slipno-list-dialog",
  templateUrl: "./spmt10311-slipno-list-dialog.component.html",
  styleUrls: ["./spmt10311-slipno-list-dialog.component.css"],
})
export class Spmt10311SlipNoListDialogComponent implements OnInit {
  formGroup: FormGroup;
  itemTableWidth: any;
  itemRecordCount: number = 0;
  itemsShow: SlipNoDto[] = [];
  items: SlipNoDto[] = [];
  itemSelect: SlipNoDto = null;
  userList: DropdownListOfUserDto[] = []

  displayedColumns: string[] = ["slipNo", "slipCreateDate", "requesterNm", "approverNm"];

  columnDefs: TableColumnDef[] = [
    { columnId: "slipNo", header: "伝票番号", width: 150, align: "left" },
    { columnId: "slipCreateDate", header: "伝票作成日", width: 150, align: "center" },
    { columnId: "requesterNm", header: "申請者", width: 170, align: "left" },
    { columnId: "approverNm", header: "承認者", width: 170, align: "left" },
  ];
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spmt10311SlipNoListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { storeCd: string, accessInfo: AccessInfo, isFPMT1031: boolean},
    private fb: FormBuilder,
    public commonService: CommonService,
    private message: MessageService,
    private httpBasic: HttpBasicService
  ) {
    this.formGroup = this.fb.group({
      slipCreateDateFrom: [],
      slipCreateDateTo: [],
      requesterCd: [Spmt10311SlipNoListConstant.EMPTY_STRING],
      approverCd: [Spmt10311SlipNoListConstant.EMPTY_STRING]
    });
  }

  ngOnInit(){
    this.calcTableWidth();
    this.initPrc();
    this.searchPrc()
  }

  calcTableWidth() {
    let width = 1
    for (let colDef of this.columnDefs) {
      width = width + colDef.width + 1 + 8;
    }
    this.itemTableWidth = {"width": "" + width + "px"};
  }

  initPrc(){
    const req: ReqGetDropdownListOfUser = {
      access: this.data.accessInfo
    };
    let subcription = this.httpBasic.getDropdownListOfUser(req).subscribe(
      result => {
        this.checkResultInit(result);
        subcription.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subcription.unsubscribe();
      }
    );
  }

  checkResultInit(res: RspGetDropdownListOfUser){
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(res)) return;
    this.userList = res.userList;
  }

  fnFormatDate(date: string): string {
    if (!date) {
      return Spmt10311SlipNoListConstant.EMPTY_STRING;
    }
    return formatDate(date, Spmt10311SlipNoListConstant.DATE_FORMAT, 'en_US')
  }

  btnSearchClick() {
    this.clearSearchResult();
    this.searchPrc();
  }

  getPageDto():ReqPageDto {
    return {
      pageNum: Spmt10311SlipNoListConstant.ZERO,
      dispNum: Spmt10311SlipNoListConstant.LIMIT_RECORD
    }
  }

  searchPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10311SlipNoListConstant.MESSAGE_LOADING);
    const req: ReqSpmt10311GetSlipNoList = {
      access: this.data.accessInfo,
      funcId: this.data.isFPMT1031 ? Spmt10311SlipNoListConstant.FPMT1031 : Spmt10311SlipNoListConstant.FPMT1036,
      storeCd: this.data.storeCd,
      slipCreateDateFrom: this.fnFormatDate(this.formGroup.get(Spmt10311SlipNoListConstant.SLIP_CREATE_DATE_FROM_CTRL).value),
      slipCreateDateTo: this.fnFormatDate(this.formGroup.get(Spmt10311SlipNoListConstant.SLIP_CREATE_DATE_TO_CTRL).value),
      requesterCd: this.formGroup.get(Spmt10311SlipNoListConstant.REQUESTERCD_CTRL).value,
      approverCd: this.formGroup.get(Spmt10311SlipNoListConstant.APPROVERCD_CTRL).value,
      pageDto: this.getPageDto()
    };
    let subcription = this.httpBasic.getSlipNoListSpmt10311(req).subscribe(
      result => {
        this.checkResult(result);
        subcription.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subcription.unsubscribe();
      }
    );
  }

  checkResult(res: RspSpmt10311GetSlipNoList){
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(res)) return;

    if (res.rows.length == 0) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI100001])
      return;
    }

    if (res.rows.length > Spmt10311SlipNoListConstant.MAXITEMS) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle,this.message.message[MI200003].replace(/%1/g,Spmt10311SlipNoListConstant.MAXITEMS));
    }

    for(let i = 0; i < Spmt10311SlipNoListConstant.MAXITEMS; i ++){
      if(i >= res.rows.length) break;
      this.items.push(res.rows[i])
    }

    this.itemSelect = null;
    this.itemRecordCount = this.items.length ?? 0;
    this.pageChanged();
  }

  clearSearchResult(){
    this.matPagenatorItem.pageIndex = 0;
    this.matPagenatorItem._changePageSize(25);
    this.itemSelect = null;
    this.items = [];
    this.itemsShow = [];
    this.itemRecordCount = 0;
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0){
      this.pageChanged();
    }
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
  }

  isHightlight(row: SlipNoDto){
    if(row.slipNo === this.itemSelect?.slipNo) return true;
    return false;
  }

  onRowClick(row: SlipNoDto): void {
    this.itemSelect = row;
  }

  btnSelectClick() {
    this.dialogRef.close(this.itemSelect);
  }
}
