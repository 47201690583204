import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { BarcodeDialogComponent } from 'src/app/dialog/barcode-dialog/barcode-dialog.component';
import { SectionHeaderUpComponent } from 'src/app/partsCommon/section-header-up/section-header-up.component';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ScheduleDto, StoreDto } from 'src/app/response/rsp-inventory-schedule-list';
import { InvMonthListDto } from 'src/app/response/rsp-inventory-month-list';
import { RspSpiv00081Init, RspSpiv00081InitDto, Spiv00081DateListDto } from 'src/app/response/rsp-spiv00081-list';
import { MatPaginator } from '@angular/material/paginator';
import { InvScheduleSearchDto } from 'src/app/request/req-inventory-schedule-list';
import { ProductNmSearchDto } from 'src/app/request/req-product-name-search';
import { Spiv00081UpdateDto, ReqSpiv00081Update } from 'src/app/request/req-spiv00081-update';
import { Spiv00081ResultDto, RspSpiv00081Update } from 'src/app/response/rsp-spiv00081-update';
import { Spiv00081ConditionSearchDto } from 'src/app/request/req-spiv00081-condition-search';
import { MatCheckboxChange } from '@angular/material/checkbox/checkbox';
import { MatTableDataSource } from '@angular/material/table';
import { Spiv00081DetailComponent } from './spiv00081.detail.component';
import { Spiv00081SearchDto } from 'src/app/request/req-spiv00081-search';
import * as ConstMessageID from 'src/app/common/const-message-id';
import { MessageService } from 'src/app/service/message.service';
import { UserInfoDto } from 'src/app/response/rsp-user-info';

@Component({
  selector: 'app-spiv00081',
  templateUrl: './spiv00081.component.html',
  styleUrls: ['./spiv00081.component.css']
})

@Injectable({
  providedIn: 'root'
})


export class Spiv00081Component implements OnInit, OnDestroy  {

  @ViewChild("sectionSuppHeader", { static: true }) sectionSupp: SectionHeaderComponent;
  @ViewChild("sectionUpHeader", { static: true }) sectionUp: SectionHeaderUpComponent;
  @ViewChild(MatPaginator, {static:false}) matPaginator: MatPaginator;

  detailDialog: MatDialogRef<Spiv00081DetailComponent>;

  public formGroup: FormGroup;
  public qtyFormGroup: FormGroup;
  public diffCntFormGroup: FormGroup;
  public diffAmtFormGroup: FormGroup;
  public itemGroup: FormGroup;
  loginFormGroup = this.fb.group({
    userId : ['', Validators.required],
    passwd : ['', Validators.required]
  });


  userInfo: UserInfoDto[] = [];
  stores: StoreDto[] = [];
  scheduleLists: ScheduleDto[] = [];
  monthLists: InvMonthListDto[] = [];
  invDiffLists: RspSpiv00081InitDto[] = [];
  originDto: RspSpiv00081InitDto[] = [];
  invDiff: RspSpiv00081InitDto[] = [];
  invDiffDateLists: Spiv00081DateListDto[] = [];
  invDiffChecked:Spiv00081UpdateDto[] = [];
  invDiffConfirmResultDto: Spiv00081ResultDto[] = [];
  invDiffListDisplay = new MatTableDataSource<RspSpiv00081InitDto>([]);
  invDiffConfirmUpdateArray: Spiv00081UpdateDto[] = [];
  searchData: any;
  parentPage: any;
  categoryList: any;
  gMainClassificationList: any;
  gMediumClassificationList: any;
  gSmallClassificationList: any;
  gSubClassificationList: any;
  public midMain : any;
  public smallMid : any;
  public smallMain : any ;
  public subSmall: any;
  public subMid: any;
  public subMain: any;
  items: any = [];
  investigateSbj: string = "";
  public recordCount: number = 0;
  FUNCTIONID: string = "FPIV0008";
  selectedId: any;
  status: any;
  statusCtrl: any;
  processStatus: any;
  actualInvDate: any;
  cmpnyCd: any;
  storeCd: any;
  sysModeCd: any;
  invPeriod: any;
  invScheduleId: any;
  invMonth: any;
  invYearMonth: any;
  conditionSts: any;
  processSts: any;
  invDiffId: any;
  invDiffCreateDate: any;
  conditionItem: any;
  investigateSts: any;
  invThreshold: any;

  searchExpandBtn: boolean;
  expandBtn: boolean;
  public isChecked: FormControl = new FormControl();

  maxLengthMsg = this.messageEntity.message[ConstMessageID.ME100015].replace('%1', '-999999').replace('%2', '999999');
  maxLengthAmtMsg = this.messageEntity.message[ConstMessageID.ME100015].replace('%1', '-99999999999').replace('%2', '99999999999');
  startEndMsg = this.messageEntity.message[ConstMessageID.ME100014];
  integerMsg = this.messageEntity.message[ConstMessageID.ME100013];

  public tableWidth: any;
  public displayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'productCd', header: "商品コード", width: 200, editable: true },
    { columnId: 'productNm', header: "商品名", width: 300},
    { columnId: 'standard', header: "規格", width: 200},
    { columnId: 'inventoryCnt', header: "棚卸数", width: 100, align: "right", editable: true },
    { columnId: 'stockQty', header: "在庫数", width: 100, align: "right" },
    { columnId: 'diffAmount', header: "差異数量", width: 100, align: "right" },
    { columnId: 'diffRate', header: "差異率", width: 100, align: "right" },
    { columnId: 'costPrice', header: "POS原価(抜)", width: 100, align: "right" },
    { columnId: 'costAmount', header: "POS原価金額(抜)", width: 100, align: "right" },
    { columnId: 'diffCostAmt', header: "差異原価金額", width: 100, align: "right" },
    { columnId: 'sellPrice', header: "売価(抜)", width: 100, align: "right" },
    { columnId: 'sellAmount', header: "売価金額(抜)", width: 100, align: "right" },
    { columnId: 'diffSellAmt', header: "差異原価金額", width: 100, align: "right" },
    { columnId: 'largeClass', header: "大分類", width: 300},
    { columnId: 'midClass', header: "中分類", width: 400},
    { columnId: 'smallClass', header: "小分類", width: 400},
    { columnId: 'subClass', header: "	細分類", width: 400},
  ];

  public isRowHidden: boolean;
  public isCheckedConfirm: boolean;
  isDisabledBtn: boolean;
  unitType: string;
  qtyErrorMessage: string;
  diffCntErrorMessage: string;
  diffAmtErrorMessage: string;

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService,
    private dialog: MatDialog,
    private router: Router,
    protected messageEntity: MessageService,
  ) {}

  ngOnInit() {
    this.storeCd = this.commonService.loginUser.realStoreCd;
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.commonService.openSpinner(this.commonService.pageTitle, "初期化中・・・");
    this.searchExpandBtn = true;
    this.expandBtn = true;
    this.setDisplayCol();
    this.qtyFormGroup = this.fb.group({
      numOfInvFrom: ["", [this.integerLengthValidator(6)]],
      numOfInvTo: ["", [this.integerLengthValidator(6)]],
      stockQtyFrom: ["", [this.integerLengthValidator(6)]],
      stockQtyTo: ["", [this.integerLengthValidator(6)]],
      },
      { validators: this.configCountValidator });
      this.qtyFormGroup.statusChanges.subscribe(() => {
        this.checkValues();
    });
    this.diffCntFormGroup = this.fb.group({
      diffQuantityFrom: ["", [this.integerLengthValidator(6)]],
      diffQuantityTo: ["", [this.integerLengthValidator(6)]],
      },
      { validators: this.configCountValidator });
      this.diffCntFormGroup.statusChanges.subscribe(() => {
        this.checkValues();
    });
    this.diffAmtFormGroup = this.fb.group({
      diffCostAmtFrom: ["", [this.integerLengthValidator(11)]],
      diffCostAmtTo: ["", [this.integerLengthValidator(11)]],
      diffSellPriceFrom: ["", [this.integerLengthValidator(11)]],
      diffSellPriceTo: ["", [this.integerLengthValidator(11)]],
      },
      { validators: this.configCountValidator });
      this.diffAmtFormGroup.statusChanges.subscribe(() => {
        this.checkValues();
    });
    this.itemGroup = this.fb.group({
      checkboxes: this.fb.array([])
    });
    this.initFormGroup();
    this.initLists();
  }

  get checkboxes(): FormArray {
    return this.itemGroup.get('checkboxes') as FormArray;
  }

  async initLists(): Promise<void> {
    try {
      await this.getUserInfo();
      await this.getStoreLists();
      await this.initCategoryList();

      if (this.commonService.fpiv0008ParentPage != undefined) {
          this.searchData = this.commonService.fpiv0008SearchData;
          this.commonService.fpiv0008SearchData = undefined;
          this.parentPage = this.commonService.fpiv0008ParentPage;
          this.commonService.fpiv0008ParentPage = undefined;
        // Check search data is exist or not
        if (this.searchData !== undefined) {

          await this.getInvMonthLists(this.searchData.storeCd);
          this.selectedId = this.searchData.invScheduleId??"";
          this.invMonth = this.searchData.invYearMonth??"";
          this.status = this.searchData.conditionSts??"";
          this.processSts = this.searchData.processSts??"";
          this.actualInvDate = this.searchData.actualInvDate??"";
          this.invDiffId = this.searchData.invDiffId??"";
          this.invDiffCreateDate = this.searchData.invDiffCreateDate??"";
          this.isRowHidden = this.searchData.investigateSts === "" ? true : false;
          this.unitType = this.searchData.conditionItem??"";

          this.formGroup.get("storeCd").setValue(this.searchData.storeCd??"");
          this.formGroup.get("invPeriod").setValue(this.searchData.invPeriod??"");
          this.formGroup.get("invScheduleId").setValue(this.searchData.invScheduleId??"");
          this.formGroup.get("invMonth").setValue(this.searchData.invYearMonth??"");
          this.formGroup.get("conditionSts").setValue(this.searchData.conditionSts??"");
          this.formGroup.get("processSts").setValue(this.searchData.processSts??"");
          this.formGroup.get("actualInvDate").setValue(this.searchData.actualInvDate??"");
          this.formGroup.get("investigateSts").setValue(this.searchData.investigateSts??"");
          this.formGroup.get("conditionItem").setValue(this.searchData.conditionItem??"");
          this.formGroup.get("invThreshold").setValue(this.searchData.invThreshold??"");
          this.formGroup.get("productCd").setValue(this.searchData.productCd??"");
          this.formGroup.get("productNm").setValue(this.searchData.productNm??"");
          this.formGroup.get("largeClassCd").setValue(this.searchData.largeClass??"");
          this.formGroup.get("midClassCd").setValue(this.searchData.midClass??"");
          this.formGroup.get("smallClassCd").setValue(this.searchData.smallClass??"");
          this.formGroup.get("subClassCd").setValue(this.searchData.subClass??"");
          this.qtyFormGroup.get("numOfInvFrom").setValue(this.searchData.numOfInvFrom??"");
          this.qtyFormGroup.get("numOfInvTo").setValue(this.searchData.numOfInvTo??"");
          this.qtyFormGroup.get("stockQtyFrom").setValue(this.searchData.stockQtyFrom??"");
          this.qtyFormGroup.get("stockQtyTo").setValue(this.searchData.stockQtyTo??"");
          this.diffCntFormGroup.get("diffQuantityFrom").setValue(this.searchData.diffQuantityFrom??"");
          this.diffCntFormGroup.get("diffQuantityTo").setValue(this.searchData.diffQuantityTo??"");
          this.diffAmtFormGroup.get("diffCostAmtFrom").setValue(this.searchData.diffCostAmtFrom??"");
          this.diffAmtFormGroup.get("diffCostAmtTo").setValue(this.searchData.diffCostAmtTo??"");
          this.diffAmtFormGroup.get("diffSellPriceFrom").setValue(this.searchData.diffSellPriceFrom??"");
          this.diffAmtFormGroup.get("diffSellPriceTo").setValue(this.searchData.diffSellPriceTo??"");
          this.formGroup.get("isCheckedUnConfirm").setValue(this.searchData.isCheckedUnConfirm??"");
          this.formGroup.get("isCheckedInvest").setValue(this.searchData.isCheckedInvest??"");
          await this.getScheduleLists();
          await this.getInvDiffCondition();
          this.formGroup.get("invDiffId").setValue(this.searchData.invDiffId??"");
          this.formGroup.get("invDiffCreateDate").setValue(this.searchData.invDiffCreateDate??"");
        }
      }else{
        await this.getInvMonthLists(this.storeCd);
      }
        this.doSearchInvDiffLists();
        this.commonService.closeSpinner();
    } catch (error) {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
    }
  }

  getUserInfo() :Promise<void>{
    return new Promise((resolve, reject) => {
      var request = {
        access: { ...this.commonService.loginUser },
      };
      let sub =this.httpBasic.generalRequest("GetUserInfo", request).subscribe(
        response => {
          sub.unsubscribe();
          this.receiveUserInfo(response);
          resolve();
        },
        error => {
          sub.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveUserInfo(response) {
    if (this.httpBasic.handleAppError(response)) return;
    this.userInfo = [];
    if (response.rows.length > 0) {
      this.userInfo = response.rows;
      this.cmpnyCd = this.userInfo[0].mainCmpnyCd;
      this.storeCd = this.userInfo[0].mainStoreCd;
      this.sysModeCd = this.userInfo[0].sysModeCd;
    }else{
      this.userInfo = [];
      this.cmpnyCd = "";
      this.storeCd = "";
      this.sysModeCd = "";
    }
  }

  // get 店舗 dropdown list
  getStoreLists(): Promise<void>{
    return new Promise((resolve, reject) => {
      var request = {
        access: { ...this.commonService.loginUser },
        cmpyCd: this.cmpnyCd,
        storeCd: this.storeCd,
        sysModeCd: this.sysModeCd
      };
      let sub =this.httpBasic.generalRequest("GetStoreList", request).subscribe(
        response => {
          sub.unsubscribe();
          this.receiveStoreList(response);
          resolve();
        },
        error => {
          sub.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveStoreList(response) {
    if (this.httpBasic.handleAppError(response)) return;

    this.stores = [];
    if(response.rows.length > 0){
      this.formGroup.get("storeCd").setValue(this.storeCd);
      this.stores = response.rows;
    }else{
      this.status = "";
      this.processSts = "";
      this.actualInvDate = "";
    }

    if (this.stores.length === 1) {
      this.formGroup.get("storeCd").disable();
    } else {
      this.formGroup.get("storeCd").enable();
    }
  }

  // get 棚卸期年月 dropdown list
  getInvMonthLists(storeCd: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const request = {
        storeCd: storeCd,
        cmpyCd: this.cmpnyCd,
        order: "1",
        access: { ...this.commonService.loginUser }
      };
      let sub = this.httpBasic.post("GetInvMonthList", request).subscribe(
        async response => {
          sub.unsubscribe();
          this.receiveMonthList(response);
          await this.scheduleChanged();
          resolve();
        },
        error => {
          sub.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveMonthList(response) {
    if (this.httpBasic.handleAppError(response)) return;
    this.monthLists = [];
    if (response.rows.length > 0) {
      this.monthLists = response.rows;
      this.formGroup.get("invScheduleId").enable();
    }else{
      this.formGroup.get("invScheduleId").disable();
      this.monthLists = [];
    }

    if (response.selected.length > 0) {
      this.selectedId = response.selected[0].invScheduleId;
      this.invMonth = response.selected[0].invMonth;
      this.formGroup.get("invPeriod").setValue(response.selected[0].invPeriod);
      this.formGroup.get("invScheduleId").setValue(response.selected[0].invScheduleId);
      this.formGroup.get("invMonth").setValue(response.selected[0].invMonth);
    }else{
      this.selectedId = "";
      this.invMonth = "";
      this.formGroup.get("invPeriod").setValue("");
      this.formGroup.get("invScheduleId").setValue("");
      this.formGroup.get("invMonth").setValue("");
    }
  }

  // get 状態、 実施棚卸日、 棚卸差異作成日 dropdown list
  getScheduleLists(): Promise<void>{
    return new Promise((resolve, reject) => {
      var request: InvScheduleSearchDto = {
        invScheduleId: this.formGroup.get("invScheduleId").value,
        storeCd: this.formGroup.get("storeCd").value,
        cmpyCd: this.cmpnyCd,
        invMonth: this.formGroup.get("invMonth").value,
        access: { ...this.commonService.loginUser },
      };
      let sub = this.httpBasic.generalRequest("GetInvScheduleList", request).subscribe(
        response => {
          sub.unsubscribe();
          this.receiveSchduleList(response);
          resolve();
        },
        error => {
          sub.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveSchduleList(response) {
    if (this.httpBasic.handleAppError(response)) return;
    const uniqueIds = new Set<string>();
    if (response.rows.length > 0) {
      this.status = response.rows[0].invProgressSts;
      this.processSts = response.rows[0].processStatus;
      this.actualInvDate = response.rows[0].actualInvDate;
      this.investigateSts = response.rows[0].investigateSts;
      this.conditionItem = response.rows[0].conditionItem;
      this.invThreshold = response.rows[0].invThreshold;
      this.formGroup.get("conditionSts").setValue(response.rows[0].invProgressSts);
      this.formGroup.get("processSts").setValue(response.rows[0].processStatus);
      this.formGroup.get("actualInvDate").setValue(response.rows[0].actualInvDate);

      this.invDiffDateLists = response.rows.filter((data: any) => {
        if (uniqueIds.has(data.invDiffCreateDate)) {
          return false; // Filter out duplicates
        } else {
          uniqueIds.add(data.invDiffCreateDate);
          return true;
        }
      }).map((data: any) => {
        return {
          invDiffCreateDate: data.invDiffCreateDate,
          invDiffId: data.invDiffId
        };
      })
      .sort((a, b) => {
        // Sort by invDiffCreateDate in descending order
        return new Date(b.invDiffCreateDate).getTime() - new Date(a.invDiffCreateDate).getTime();
      });

      this.invDiffId = this.invDiffDateLists[0].invDiffId;
      this.invDiffCreateDate = this.invDiffDateLists[0].invDiffCreateDate;
      this.formGroup.get("invDiffId").enable();
      this.formGroup.get("invDiffId").setValue(this.invDiffDateLists[0].invDiffId);
      this.formGroup.get("invDiffCreateDate").setValue(this.invDiffDateLists[0].invDiffCreateDate);
    }else{
      this.status = "";
      this.processSts = "";
      this.actualInvDate = "";
      this.investigateSts = "";
      this.conditionItem = "";
      this.invThreshold = "";
      this.formGroup.get("invDiffId").disable();
      this.formGroup.get("conditionSts").setValue("");
      this.formGroup.get("processSts").setValue("");
      this.formGroup.get("actualInvDate").setValue("");
    }
  }

  // get 要調査条件項目、要調査条件閾値
  getInvDiffCondition(): Promise<void>{
    return new Promise((resolve, reject) => {
      const request: Spiv00081ConditionSearchDto = {
        invScheduleId: this.formGroup.get("invScheduleId").value,
        cmpny: this.cmpnyCd,
        access: { ...this.commonService.loginUser },
      };
      let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
      let sub = this.httpBasic.generalRequest("GetInvDiffCondition", request).subscribe(
        response => {
          sub.unsubscribe();
          this.commonService.closeSpinnerForSubComp(ref);
          this.receiveInvDiffCondition(response);
          resolve();
        },
        error => {
          sub.unsubscribe();
          this.commonService.closeSpinnerForSubComp(ref);
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveInvDiffCondition(response) {
    if (this.httpBasic.handleAppError(response)) return;
    if (response.rows.length > 0 ) {
      this.isRowHidden = response.rows[0].investigateSts === "" ? true : false;
      this.unitType = response.rows[0].conditionItem;
      if(!this.isRowHidden){
        this.conditionItem = response.rows[0].conditionItem;
        this.investigateSts = response.rows[0].investigateSts;
        this.invThreshold = response.rows[0].invThreshold;
        this.formGroup.get("conditionItem").setValue(this.conditionItem);
        this.formGroup.get("investigateSts").setValue(this.investigateSts);
        this.formGroup.get("invThreshold").setValue(this.invThreshold);
      }else{
        this.conditionItem = "";
        this.investigateSts = "";
        this.invThreshold = "";
        this.formGroup.get("conditionItem").setValue("");
        this.formGroup.get("investigateSts").setValue("");
        this.formGroup.get("invThreshold").setValue("");
      }
    }else{
      this.conditionItem = "";
      this.investigateSts = "";
      this.invThreshold = "";
      this.formGroup.get("conditionItem").setValue("");
      this.formGroup.get("investigateSts").setValue("");
      this.formGroup.get("invThreshold").setValue("");
    }
  }

  // get 大分類、 中分類、 小分類、 細分類 dropdown list
  initCategoryList(): Promise<void>{
    return new Promise((resolve, reject) => {
      var request  = {
        access: { ...this.commonService.loginUser }
      }
      let sub = this.httpBasic.initCategoryList(request).subscribe(
        result => {
          sub.unsubscribe();
          this.checkResultCategory(result)
          resolve();
        },
        error => {
          sub.unsubscribe();
          this.httpBasic.handleError(error);
          this.commonService.closeSpinner();
          reject(error);
        }
      );
    });
  }

  checkResultCategory(response: any) {
    // this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.gMainClassificationList   = response.gMainClassificationResult;
    this.gMediumClassificationList = response.gMediumClassificationResult;
    this.gSmallClassificationList  = response.gSmallClassificationResult;
    this.gSubClassificationList    = response.gSubClassificationResult;
  }

  // change according to 大分類 dropdown list
  fnOnChangeMainClassCd(event:any){
    this.formGroup.get("midClassCd").setValue("");
    this.formGroup.get("smallClassCd").setValue("");
    this.formGroup.get("subClassCd").setValue("");
  }

  // change according to 中分類 dropdown list
  fnOnChangeMidClassCd(event:any){
    const selectedCd = event.target.value;
      this.formGroup.get("smallClassCd").setValue("");
      this.formGroup.get("subClassCd").setValue("");
      this.gMediumClassificationList.forEach((data:any,i:any) => {
        if(selectedCd == data.gMiddleClassCdFv){
            this.midMain = data.gMainClassCdFv;

            this.gMainClassificationList.forEach((element:any,i : any) =>{
            if(this.midMain == element.gMainClassCdFv){
              this.formGroup.get("largeClassCd").setValue(this.midMain);
            }
          });
        }
      });
  }

  // change according to 小分類 dropdown list
  fnOnChangeSmallClassCd(event:any){
    const selectedCd = event.target.value;
    this.formGroup.get("subClassCd").setValue("");
    this.gSmallClassificationList.forEach((data:any,i:any) => {
      if(selectedCd == data.gSmallClassCdFv){
        this.smallMid = data.gMiddleClassCdFv;

        this.gMediumClassificationList.forEach((data:any,i:any) => {
          if(this.smallMid == data.gMiddleClassCdFv){
            this.formGroup.get("midClassCd").setValue(this.smallMid);
            this.smallMain = data.gMainClassCdFv;

            this.gMainClassificationList.forEach((data:any,i:any) => {
              if(this.smallMain == data.gMainClassCdFv){
                this.formGroup.get("largeClassCd").setValue(this.smallMain);
              }
            })
          }
        })
      }
    })
  }

  // change according to 細分類 dropdown list
  fnOnChangeSubClassCd(event:any){
    const selectedCd = event.target.value;
    this.gSubClassificationList.forEach((data: any,i : any) => {
      if(selectedCd == data.gSubClassCdFv){
        this.subSmall = data.gSmallClassCdFv;

        this.gSmallClassificationList.forEach((data : any, i: any) => {
          if(this.subSmall == data.gSmallClassCdFv){
            this.formGroup.get("smallClassCd").setValue(this.subSmall);
            this.subMid = data.gMiddleClassCdFv;

            this.gMediumClassificationList.forEach((data:any,i:any) => {
              if(this.subMid == data.gMiddleClassCdFv){
                this.formGroup.get("midClassCd").setValue(this.subMid);
                this.subMain = data.gMainClassCdFv;

                this.gMainClassificationList.forEach((data:any,i:any) => {
                  if(this.subMain == data.gMainClassCdFv){
                    this.formGroup.get("largeClassCd").setValue(this.subMain);
                  }
                })
              }
            })
          }
        })
      }
    })
  }

  // Check null inputs
  isEmpty(str: string) {
    str = str + "";
    if (str == "null" || str == "" || str == "undefined" || str == null || str == undefined || str.length == 0) {
      return true;
    }
    return false;
  }

  onCheckNull(item: any) {
    if (this.isEmpty(item?.toString()?.trim())) {
      return true;
    }
    return false;
  }

  // search  for 検索結果 according to 検索条件
  doSearchInvDiffLists(){
    this.invDiffChecked=[];
    this.itemGroup.markAsPristine();

    this.statusCtrl = this.status;
    if(this.onCheckNull(this.formGroup.get("storeCd").value) || this.onCheckNull(this.formGroup.get("invMonth").value)
    || this.onCheckNull(this.formGroup.get("invDiffCreateDate").value) || this.formGroup.get("conditionSts").value==="01"){
      this.recordCount = 0;
      this.invDiffLists = [];
      this.invDiffListDisplay.data = [];
      return;
    }

    let unConfirmFLG = this.formGroup.get("isCheckedUnConfirm").value ? "0" : "";
    let investFLG = this.formGroup.get("isCheckedInvest").value ? "1" : "";
    this.storeCd = this.formGroup.get("storeCd").value;
    this.invYearMonth = this.formGroup.get("invMonth").value;

    let request : Spiv00081SearchDto = {
      access: { ...this.commonService.loginUser },
      storeCd: this.storeCd,
      cmpyCd: this.cmpnyCd,
      invPeriod: this.formGroup.get("invPeriod").value,
      invScheduleId: this.formGroup.get("invScheduleId").value,
      invYearMonth: this.invYearMonth,
      conditionSts: this.formGroup.get("conditionSts").value,
      processSts: this.formGroup.get("processSts").value,
      actualInvDate: this.formGroup.get("actualInvDate").value,
      invDiffId: this.formGroup.get("invDiffId").value,
      invDiffCreateDate: this.formGroup.get("invDiffCreateDate").value,
      investigateSts: this.formGroup.get("investigateSts").value,
      conditionItem: this.formGroup.get("conditionItem").value,
      invThreshold: this.formGroup.get("invThreshold").value,
      productCd: this.formGroup.get("productCd").value,
      productNm: this.formGroup.get("productNm").value,
      largeClass: this.formGroup.get("largeClassCd").value,
      midClass: this.formGroup.get("midClassCd").value,
      smallClass: this.formGroup.get("smallClassCd").value,
      subClass: this.formGroup.get("subClassCd").value,
      numOfInvFrom: this.qtyFormGroup.get("numOfInvFrom").value,
      numOfInvTo: this.qtyFormGroup.get("numOfInvTo").value,
      stockQtyFrom: this.qtyFormGroup.get("stockQtyFrom").value,
      stockQtyTo: this.qtyFormGroup.get("stockQtyTo").value,
      diffQuantityFrom: this.diffCntFormGroup.get("diffQuantityFrom").value,
      diffQuantityTo: this.diffCntFormGroup.get("diffQuantityTo").value,
      diffCostAmtFrom: this.diffAmtFormGroup.get("diffCostAmtFrom").value,
      diffCostAmtTo: this.diffAmtFormGroup.get("diffCostAmtTo").value,
      diffSellPriceFrom: this.diffAmtFormGroup.get("diffSellPriceFrom").value,
      diffSellPriceTo: this.diffAmtFormGroup.get("diffSellPriceTo").value,
      isCheckedUnConfirm: unConfirmFLG,
      isCheckedInvest: investFLG,
    }
    this.searchData = request;

    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "検索中・・・");
    this.httpBasic.generalRequest("Spiv00081Search",request).subscribe(
      result => {
        this.checkResult(result);
        this.commonService.closeSpinnerForSubComp(ref);
      },
      error => {
        this.httpBasic.handleError(error);
        this.commonService.closeSpinnerForSubComp(ref);
        this.commonService.closeSpinner();
      }
    );
  }

  checkResult(response: RspSpiv00081Init) {

    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      this.doClear();
      return;
    }
    if (response.rows.length === 0) {
        this.invDiffLists = [];
        this.invDiffListDisplay.data = [];
        this.recordCount = 0;
        return;
    }

    this.invDiffLists = [];
    if (this.checkboxes instanceof FormArray) {
      this.checkboxes.clear();
    }
    for (const data of response.rows) {
      this.invDiffLists.push(data);
      const checkboxItem = { invDiffId: data.invDiffId, checkFlg: data.checkFlg };
      this.checkboxes.push(this.fb.group(checkboxItem));
    }
    this.recordCount = this.invDiffLists.length;
    if (this.matPaginator) {
      this.matPaginator.pageIndex = 0;
    }
    this.pageChanged();
  }

  // check for input value comparison
  configCountValidator(control: AbstractControl): ValidationErrors | null {
    const numOfInvFrom = parseInt(control.get("numOfInvFrom")?.value);
    const numOfInvTo = parseInt(control.get("numOfInvTo")?.value);

    const stockQtyFrom = parseInt(control.get("stockQtyFrom")?.value);
    const stockQtyTo = parseInt(control.get("stockQtyTo")?.value);

    const diffQuantityFrom = parseInt(control.get("diffQuantityFrom")?.value);
    const diffQuantityTo = parseInt(control.get("diffQuantityTo")?.value);

    const diffCostAmtFrom = parseInt(control.get("diffCostAmtFrom")?.value);
    const diffCostAmtTo = parseInt(control.get("diffCostAmtTo")?.value);

    const diffSellPriceFrom = parseInt(control.get("diffSellPriceFrom")?.value);
    const diffSellPriceTo = parseInt(control.get("diffSellPriceTo")?.value);

    if (!Number.isNaN(numOfInvFrom) && !Number.isNaN(numOfInvTo) && numOfInvFrom > numOfInvTo) {
      return { greaterThanError: true };
    }else if (!Number.isNaN(stockQtyFrom) && !Number.isNaN(stockQtyTo) && stockQtyFrom > stockQtyTo) {
      return { greaterThanError: true };
    }else if (!Number.isNaN(diffQuantityFrom) && !Number.isNaN(diffQuantityTo) && diffQuantityFrom > diffQuantityTo) {
      return { greaterThanError: true };
    }else if (!Number.isNaN(diffCostAmtFrom) && !Number.isNaN(diffCostAmtTo) && diffCostAmtFrom > diffCostAmtTo) {
      return { greaterThanError: true };
    }else if (!Number.isNaN(diffSellPriceFrom) && !Number.isNaN(diffSellPriceTo) && diffSellPriceFrom > diffSellPriceTo) {
      return { greaterThanError: true };
    }

    return null;
  }

  // add error message for input value comparison
  checkValues() {
    const qtyErrors = this.qtyFormGroup.errors;
    const diffCntErrors = this.diffCntFormGroup.errors;
    const diffAmtErrors = this.diffAmtFormGroup.errors;

    if (qtyErrors?.greaterThanError) {
      this.qtyErrorMessage = this.startEndMsg;
    } else {
      this.qtyErrorMessage = "";
    }
    if (diffCntErrors?.greaterThanError) {
      this.diffCntErrorMessage = this.startEndMsg;
    } else {
      this.diffCntErrorMessage = "";
    }
    if (diffAmtErrors?.greaterThanError) {
      this.diffAmtErrorMessage = this.startEndMsg;
    } else {
      this.diffAmtErrorMessage = "";
    }
  }

  // check value if it's empty or not
  paramIsNull(data: AbstractControl) {
    return  data.value === "" ? true : false;
  }

  // check if input value is greater than limited length
  integerLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value == null || value === '') {
        return null;
      }
      if (!/^-?\d*$/.test(value)) {
        return { integer: true };
      }
      const absValue = value.replace(/^[-+]/, '');
      if (absValue.length > maxLength) {
        return { maxLength: true };
      }
      return null;
    };
  }

  // dialog box for 棚卸数
  openDetailDialog(items: any) {
    this.detailDialog = this.dialog.open(Spiv00081DetailComponent, {
      data: items,
    });

    this.detailDialog.afterClosed().subscribe(result => {
      console.log('Dialog is closed');
    });
  }

  // closeDetailDialog() {
  //   this.detailDialog.close();
  // }

  ngOnDestroy(): void {

  }

  // declare variables for initial state
  initFormGroup() {
    this.formGroup = this.fb.group({
      qtyFormGroup:this.fb.group({
        numOfInvFrom: [""],
        numOfInvTo: [""],
        stockQtyFrom: [""],
        stockQtyTo: [""],
      }),
      diffCntFormGroup:this.fb.group({
        diffQuantityFrom: [""],
        diffQuantityTo: [""],
      }),
      diffAmtFormGroup:this.fb.group({
        diffCostAmtFrom: [""],
        diffCostAmtTo: [""],
        diffSellPriceFrom: [""],
        diffSellPriceTo: [""],
      }),
      isCheckedUnConfirm: [true],
      isCheckedInvest: [false],
      cmpyCd: [this.cmpnyCd],
      storeCd: [this.storeCd],
      invScheduleId: [this.selectedId],
      invPeriod: [""],
      invMonth: [this.invMonth],
      conditionSts: [this.status],
      processSts: [{value: this.processSts, disabled: true}],
      actualInvDate: [{value: this.actualInvDate, disabled: true}],
      invDiffId:[this.invDiffId],
      invDiffCreateDate:[this.invDiffCreateDate],
      conditionItem: [this.conditionItem],
      investigateSts: [{value:this.investigateSts, disabled: true}],
      invThreshold: [{value:this.invThreshold, disabled: true}],
      productCd: [""],
      productNm: [""],
      largeClassCd: [""],
      midClassCd: [""],
      smallClassCd: [""],
      subClassCd: [""],
      checkFlg: [""],
    });
  }

  // change according to 店舗 dropdown list
  async storeChanged(){
    let index = this.stores.findIndex(store => store.storeCd === this.formGroup.get("storeCd").value);
    this.formGroup.get("storeCd").setValue(this.stores[index].storeCd);
    await this.getInvMonthLists(this.formGroup.get("storeCd").value);
    this.commonService.closeSpinner();
  }

  // change according to 棚卸期年月 dropdown list
  async scheduleChanged() {
    let index = this.monthLists.findIndex(month => month.invScheduleId === this.formGroup.get("invScheduleId").value);
    if (index !== -1) {
      this.formGroup.get("invDiffId").enable();
      this.formGroup.get("invMonth").setValue(this.monthLists[index].invMonth);
      await this.getScheduleLists();
      await this.getInvDiffCondition();
      this.commonService.closeSpinner();
    } else {
      this.formGroup.get("invDiffId").disable();
      this.formGroup.get("invMonth").setValue("");
      this.formGroup.get("processSts").setValue("");
      this.formGroup.get("actualInvDate").setValue("");
      this.formGroup.get("conditionItem").setValue("");
      this.formGroup.get("investigateSts").setValue("");
      this.formGroup.get("invThreshold").setValue("");
      this.isRowHidden = true;
      this.invDiffDateLists = [];
    }
  }

  // change inventory difference date according to dropdown lists
  invDiffDateChanged(){
    let index = this.invDiffDateLists.findIndex(invDate => invDate.invDiffId === this.formGroup.get("invDiffId").value);
    if(index !== -1){
      this.invDiffCreateDate = this.invDiffDateLists[index].invDiffCreateDate;
      this.formGroup.get("invDiffCreateDate").setValue(this.invDiffDateLists[index].invDiffCreateDate);
    }else{
      this.invDiffCreateDate = "";
      this.formGroup.get("invDiffCreateDate").setValue("");

    }
  }

  // scan product and return product name
  onScan(barcode: string) {
    let productCd = barcode;
    if (this.commonService.config.loginBarcode) {
      productCd = barcode.substring(
        this.commonService.config.loginBarcode.start,
        this.commonService.config.loginBarcode.end);
    }
    this.formGroup.get("productCd").setValue(productCd);
    this.getProductName(productCd);
  }

  // POS bar scan for product code and return product name
  openBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != undefined && data != null) {
          this.formGroup.get("productCd").setValue(data);
          this.getProductName(data);
        }
      }
    )
  }

  getProductName(item: any): Promise<void>{
    return new Promise((resolve, reject) => {
      const request: ProductNmSearchDto = {
        productCd: item,
        cmpyCd: this.formGroup.get("cmpyCd").value,
        access: { ...this.commonService.loginUser },
      };
      this.httpBasic.generalRequest("GetProductName", request).subscribe(
        response => {
          this.receiveProductNm(response);
          this.doSearchInvDiffLists();
          resolve();
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveProductNm(response) {
    if (this.httpBasic.handleAppError(response)) return;
    if (response.rows.length < 1) {
      this.formGroup.get("productNm").setValue("");
      return;
    }
    this.formGroup.get("productNm").setValue(response.rows[0].productNm);
  }

  // Clears all error messages, reinitializes form groups and lists
  doClear() {
    // this.selectedId = null;
    this.itemGroup.markAsPristine();
    this.qtyErrorMessage = "";
    this.diffCntErrorMessage = "";
    this.diffAmtErrorMessage = "";
    this.qtyFormGroup.reset(); // Reset form group controls
    this.diffCntFormGroup.reset();
    this.diffAmtFormGroup.reset();
    this.initLists().then(() => {
      this.initFormGroup();
      this.doSearchInvDiffLists();
    });
  }

  setDisplayCol() {
    this.displayColumnDefs = [];
    this.displayColumnIds = ["checkFlg","investigateSbj"];

    for (var colDef of this.columnDefs) {
      this.displayColumnDefs.push(colDef);
      this.displayColumnIds.push(colDef.columnId);
      this.calcTableWidth();
    }
  }

  calcTableWidth() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;
    width = width + 80 + 1 + 8;
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
  }

  setBtnName(){
    this.expandBtn = this.sectionUp.isSectionBodyOpen;
    return this.expandBtn ?"詳細条件を閉じる":"詳細条件を表示";
  }

  getMaxHeight(): string {
    this.searchExpandBtn = this.sectionSupp.isSectionBodyOpen;
    if (this.searchExpandBtn && this.expandBtn) {
      return '310px';
    } else if (!this.searchExpandBtn && !this.expandBtn) {
      return '581px';
    } else if (this.searchExpandBtn && !this.expandBtn) {
      return '500px';
    }
  }

  styleFor(id: string, value?: number) {
    return this.styleForBody(this.columnDefs, id, value);
  }

  styleForBody(colDefs: TableColumnDef[], id: string, value?: number) {
    const  itemThreshold =  parseInt(this.formGroup.get("invThreshold").value);
    for (let colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": `${colDef.width}px`,
          "text-align": colDef.align ? colDef.align : "left",
          "color": value < 0 ? "red" : "inherit"
        };
      }
      if (id === "diffAmount" && this.unitType == '1' &&  Math.abs(value) >= Math.abs(itemThreshold)) {
        return{
          "width": `${colDef.width}px`,
          "text-align": "right",
          "color": value < 0 ? "red" : "inherit",
          "background-color": `var(--pippi-edited-color)`,
        }
      }else if (id === "diffCostAmt" && this.unitType != '1' &&  Math.abs(value) >= Math.abs(itemThreshold)) {
        return{
          "width": `${colDef.width}px`,
          "text-align": "right",
          "color": value < 0 ? "red" : "inherit",
          "background-color": `var(--pippi-warn-2-color)`,
        }
      }
    }
  }

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }

  onClickCellSupp(columnId: string, items: any) {
    if (columnId == "productCd") {
      this.commonService.fpiv0008StoreCd = this.formGroup.get('storeCd').value;
      this.commonService.fpiv0008InvMonth = this.formGroup.get('invMonth').value;
      this.commonService.fpiv0008InvDiffId = this.formGroup.get('invScheduleId').value;
      this.commonService.fpiv0005SearchData = {
        storeCd: this.formGroup.get("storeCd")?.value,
        invScheduleId: this.formGroup.get("invScheduleId")?.value,
        invYearMonth: this.formGroup.get("invMonth")?.value,
        productCd: items.productCd,
        productNm: items.productNm,
        gmainClassCd: items.largeCd,
        gmidClassCd: items.midCd,
        gsmallClassCd: items.smallCd,
        gsubClassCd: items.subCd,
        inventoryQtyFrom: this.formGroup.get("numOfInvFrom")?.value,
        inventoryQtyTo: this.formGroup.get("numOfInvTo")?.value,
        stockQtyFrom: this.formGroup.get("stockQtyFrom")?.value,
        stockQtyTo: this.formGroup.get("stockQtyTo")?.value
      }
      this.commonService.fpiv0005ParentPage = 'fpiv0008';
      this.commonService.pageMenuName = "棚卸入力";
      this.commonService.fpiv0008SearchData = this.searchData;
      this.router.navigate(['/SPIV00051']);
    }
    if (columnId == "inventoryCnt") {
      this.openDetailDialog(items);
    }

  }

  pageChanged() {
    let start;
    let end;
    this.invDiffListDisplay.data = [];
    this.recordCount = this.invDiffLists?.length ?? 0;
    if (this.matPaginator) {
      start = this.matPaginator.pageIndex * this.matPaginator.pageSize;
      end = start + this.matPaginator.pageSize;

      // If the start index is beyond the record count, reset the paginator to the last possible page
      if (start >= this.recordCount) {
        this.matPaginator.pageIndex = Math.ceil(this.recordCount / this.matPaginator.pageSize) - 1;
        start = this.matPaginator.pageIndex * this.matPaginator.pageSize;
        end = start + this.matPaginator.pageSize;
      }
    }
    if (end > this.recordCount) {
      end = this.recordCount;
    }
    this.invDiffListDisplay.data = this.invDiffLists?.slice(start, end) ?? [];
    document.getElementById("inventory-different-list")?.scrollTo(0, 0);
  }

  lastPage() {
    this.matPaginator.pageIndex = Math.floor(this.recordCount / this.matPaginator.pageSize);
    this.pageChanged();
  }

  isButtonDisabled(): boolean{
    return this.statusCtrl !== "02";
  }

  onCheckboxChange(event: MatCheckboxChange, item: any): void {
    const checked = event.checked ? '1' : ''; // Convert boolean to string '1' or '0'
    const index = this.invDiffLists.findIndex(data => data.invDiffId === item.invDiffId);
    this.invDiffLists[index].checkFlg= checked;
    if (event.checked) {
      this.updateInvDiffConfirmDto(item, checked);
      this.itemGroup.markAsDirty();
    } else {
      this.updateInvDiffConfirmDto(item, checked);
      this.itemGroup.markAsPristine();
    }
  }

  updateInvDiffConfirmDto(rowData: any, checkFlg: string): void {
    const existingItem = this.invDiffChecked.findIndex(item => item.invDiffId === rowData.invDiffId);
    if (existingItem > -1) {
      this.invDiffChecked[existingItem].checkFlg = checkFlg;
    } else {
      this.invDiffChecked.push(rowData);
    }
  }

  // confirm the inventory difference list updates
  doConfirm(){
    const invDiffConfirmUpdateArray: Spiv00081UpdateDto[] = this.invDiffChecked.filter(row => (row.checkFlg!==''))
    .map(row => ({
      invDiffId: row.invDiffId,
      checkFlg: row.checkFlg,
      functionId: this.FUNCTIONID,
      updTime: row.updTime,
      cmpnyCd: this.cmpnyCd,
      productCd: row.productCd,
      largeCd: row.largeCd,
      midCd: row.midCd,
      smallCd: row.smallCd,
      subCd: row.subCd,
      storeCd: this.storeCd,
      invYearMonth: this.invYearMonth
    }));

    if (invDiffConfirmUpdateArray.length < 1 ) {
      return;
    }

    var request : ReqSpiv00081Update = {
      access: {...this.commonService.loginUser},
      invDiffConfirmUpdate : invDiffConfirmUpdateArray
    }
    let ref = this.commonService.openSpinnerForSubComp(this.commonService.pageTitle, "更新中・・・");
    this.httpBasic.generalRequest("Spiv00081Update",request).subscribe(
      data => {
        this.updateResult(data);
        this.commonService.closeSpinnerForSubComp(ref);
      },
      error => {
        this.commonService.closeSpinnerForSubComp(ref);
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );

  }

  updateResult(rspInvDiffConfirmEdit: RspSpiv00081Update) {
    this.invDiffChecked=[];
    this.commonService.closeSpinner();
    if (rspInvDiffConfirmEdit.fatalError && rspInvDiffConfirmEdit.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, rspInvDiffConfirmEdit.fatalError[0].errMsg);
      return;
    }
    if (rspInvDiffConfirmEdit.normalError && rspInvDiffConfirmEdit.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, rspInvDiffConfirmEdit.normalError[0].errMsg);
      this.doClear();
      return;
    }

    this.invDiffConfirmResultDto.push(rspInvDiffConfirmEdit.rows);
    if (this.checkboxes instanceof FormArray) {
      this.checkboxes.clear();
    }
    for (const data of this.invDiffConfirmResultDto) {
      this.setInvDiffConfirmList(data.invDiffId, data.checkFlg);
    }
    this.doSearchInvDiffLists();
  }

  setInvDiffConfirmList(id: string, value: string) {
    if (id !== "" || id !== null) {
      const checkboxItem = { invDiffId: id, checkFlg: value };
      this.checkboxes.push(this.fb.group(checkboxItem));
    }
  }

  canDeactivate() {
    if (!this.itemGroup.dirty) return true;

    return this.commonService.openYesNoDialog(this.commonService.pageTitle, "変更が保存されていません。変更内容を破棄しますか？");
  }

}

