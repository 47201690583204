import { formatDate } from "@angular/common";
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Subscription } from "rxjs";
import { TableColumnDef } from "src/app/common/table-column-def";
import { Req } from "src/app/request/req";
import { ReqAccess } from "src/app/request/req-access";
import { ReqPageDto } from "src/app/request/req-page-dto";
import { Rsp } from "src/app/response/rsp";
import { CommonService } from "src/app/service/common.service";
import { HttpBasicService } from "src/app/service/http-basic.service";

export interface Spmt10261SlipDto {
  slipNo: string;
  outStoreCd: string;
  inStoreCd: string;
  outRequesterCd: string;
  outRequesterName: string;
  outApproverCd: string;
  outApproverName: string;
  inApproverCd: string;
  inApproverName: string;
  slipCreateDate: string;
  hightlightFlg: boolean;
}

export interface RspSpmt10261SlipList extends Rsp {
  rows: Spmt10261SlipDto[];
  recordCount: number;
}

export interface Spmt10261SlipListInput {
  outStoreCode: string;
  access: ReqAccess | any;
}

export interface ReqSpmt10261SlipList extends Req {
  outStoreCode: string;
  slipCreateStartDate: string;
  slipCreateEndDate: string;
  outRequesterCd: string;
  outApproverCd: string;
  inApproverCd: string;
  pageInfo: ReqPageDto;
}
@Component({
  selector: "app-spmt10261-slip-list-dialog",
  templateUrl: "./spmt10261-slip-list-dialog.component.html",
  styleUrls: ["./spmt10261-slip-list-dialog.component.css"],
})
export class Spmt10261SlipListDialogComponent implements OnInit, OnDestroy, AfterViewInit {
  formGroup: FormGroup;
  startDateSubscription: Subscription;
  endDateSubscription: Subscription;
  dataSource = new MatTableDataSource<Spmt10261SlipDto>();
  recordCount: number = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  storeSearchConditions: any = {
    outStoreCode: "",
    slipCreateStartDate: "",
    slipCreateEndDate: "",
    outRequesterCd: "",
    outApproverCd: "",
    inApproverCd: "",
  };

  displayedColumns: string[] = ["slipNo", "slipCreateDate", "outRequesterName", "outApproverName", "inApproverName"];
  userList: any[] = [];

  columnDefs: TableColumnDef[] = [
    { columnId: "slipNo", header: "伝票番号", width: 80, align: "left" },
    { columnId: "slipCreateDate", header: "伝票作成日", width: 80, align: "center" },
    { columnId: "outRequesterName", header: "出庫申請者", width: 80, align: "left" },
    { columnId: "outApproverName", header: "出庫承認者", width: 80, align: "left" },
    { columnId: "inApproverName", header: "入庫承認者", width: 80, align: "left" },
  ];

  constructor(
    public dialogRef: MatDialogRef<Spmt10261SlipListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Spmt10261SlipListInput,
    private fb: FormBuilder,
    public commonService: CommonService,
    private httpBasic: HttpBasicService
  ) {
    this.formGroup = this.fb.group({
      slipCreateStartDate: [""],
      slipCreateEndDate: [""],
      outRequesterCd: [""],
      outApproverCd: [""],
      inApproverCd: [""],
    });

    this.startDateSubscription = this.formGroup.get("slipCreateStartDate").valueChanges.subscribe((value) => {
      if (!this.formGroup.get("slipCreateEndDate").value) {
        this.formGroup.get("slipCreateEndDate").setValue(value);
      }
    });

    this.endDateSubscription = this.formGroup.get("slipCreateEndDate").valueChanges.subscribe((value) => {
      if (!this.formGroup.get("slipCreateStartDate").value) {
        this.formGroup.get("slipCreateStartDate").setValue(value);
      }
    });
  }

  getUserLists() {
    let request: Req = {
      access: this.data.access,
    };
    this.httpBasic.beforeRequestStart();
    this.httpBasic.generalRequest("GetUserList", request).subscribe({
      next: (res) => {
        if (this.httpBasic.handleAppError(res)) return;
        this.userList = res.rows;
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => this.httpBasic.afterRequestComplete(),
    });
  }

  ngAfterViewInit(): void {
    this.btnSearchClick();
  }

  ngOnDestroy(): void {
    this.startDateSubscription.unsubscribe();
    this.endDateSubscription.unsubscribe();
  }

  fnGetMaxStartDate(): string {
    if (!this.formGroup.get("slipCreateEndDate").value) {
      return this.fnGetCurrentDate();
    }
    return this.formGroup.get("slipCreateEndDate").value;
  }

  fnGetCurrentDate(): string {
    const date = new Date();
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  fnGetMinDate(): string {
    return this.formGroup.get("slipCreateStartDate").value;
  }

  fnFormatDate(date: string): string {
    if (!date) {
      return "";
    }
    return formatDate(date, "yyyy-MM-dd", "en_US");
  }

  btnSearchClick() {
    if (!this.formGroup.valid) {
      return;
    }
    this.paginator.pageIndex = 0;
    this.storeSearchConditions = {
      outStoreCode: this.data.outStoreCode,
      slipCreateStartDate: this.fnFormatDate(this.formGroup.get("slipCreateStartDate").value),

      slipCreateEndDate: this.fnFormatDate(this.formGroup.get("slipCreateEndDate").value),
      outRequesterCd: this.formGroup.get("outRequesterCd").value,
      outApproverCd: this.formGroup.get("outApproverCd").value,
      inApproverCd: this.formGroup.get("inApproverCd").value,
    };
    this.fnSearch();
  }

  pageChanged() {
    this.fnSearch();
  }

  private fnSearch() {
    const req: ReqSpmt10261SlipList = {
      access: this.data.access,
      ...this.storeSearchConditions,
      pageInfo: { pageNum: this.paginator.pageIndex, dispNum: this.paginator.pageSize },
    };
    this.httpBasic.beforeRequestStart();
    this.httpBasic.getSpmt10261SlipList(req).subscribe({
      next: (data) => {
        if (this.httpBasic.handleAppError(data)) return;
        this.dataSource.data = data.rows;
        this.recordCount = data.recordCount;
      },
      error: (err) => {
        this.httpBasic.afterRequestError(err);
      },
      complete: () => {
        this.httpBasic.afterRequestComplete();
      },
    });
  }

  onRowClick(row: Spmt10261SlipDto): void {
    let temp = !row.hightlightFlg;
    this.dataSource.data.map((x) => (x.hightlightFlg = false));
    row.hightlightFlg = temp;
  }

  btnSelectClick() {
    const hightlightRow = this.dataSource.data.find((x) => x.hightlightFlg);
    if (!hightlightRow) {
      return;
    }
    this.dialogRef.close(hightlightRow);
  }

  btnCloseClick() {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.getUserLists();
  }
}
