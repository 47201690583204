import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, AbstractControl, FormGroup, FormArray, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { SectionHeaderUpComponent } from 'src/app/partsCommon/section-header-up/section-header-up.component';
import { ReqNonInventoryDataSearch } from 'src/app/request/req-non-inventory-data-search';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { BarcodeDialogComponent } from 'src/app/dialog/barcode-dialog/barcode-dialog.component';
import { InvScheduleSearchDto } from 'src/app/request/req-inventory-schedule-list';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MatCheckboxChange } from '@angular/material/checkbox/checkbox';
import { NonInvConfirmDto, ReqNonInvConfirmEdit } from 'src/app/request/req-non-inventory-confirmation-list-edit';
import { RspNonInvConfirmEdit } from 'src/app/response/rsp-non-inventory-confirm-list-edit';
import { ReqNonInventoryDataSearchProductNm } from 'src/app/request/req-non-inventory-data-search-productNm';
import { RspNonInvListInitDto, RspNonInvListInit } from 'src/app/response/rsp-non-inventory-list';
import { StoreDto } from 'src/app/response/rsp-inventory-schedule-list';
import { Spiv00051SearchDto } from 'src/app/request/req-spiv00051';
import { Spiv00091SearchDto } from 'src/app/request/req-spiv00091-search';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { NavigationExtras, Router } from '@angular/router';
import { InvMonthSearchDto } from 'src/app/request/req-inventory-month-list';
import { RspSPIV00091Init } from 'src/app/response/rsp-spiv00091-init';
import { MessageService } from 'src/app/service/message.service';
import * as MessageID from 'src/app/common/const-message-id';
import { UserInfoDto } from 'src/app/response/rsp-user-info';
@Component({
  selector: 'app-spiv00091',
  templateUrl: './spiv00091.component.html',
  styleUrls: ['./spiv00091.component.css']
})

export class SPIV00091Component implements OnInit, OnDestroy {

  @ViewChild("sectionSuppHeader", { static: true }) sectionSupp: SectionHeaderComponent;
  @ViewChild("sectionHeader", { static: true }) sectionClassificationHeader: SectionHeaderUpComponent;
  @ViewChild(MatPaginator, { static: false }) matPaginator: MatPaginator;

  private subscriptionInit: Subscription;
  private subscriptionFormGroup: Subscription;
  private subscriptionConfirm: Subscription;

  public formGroup: FormGroup;
  public tableWidth: any;
  public displayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[];
  // public columnIds: string[] = ["checkFlg","storeCd", "storeNm","un", "stockNumber","posCost", "salePrice","mainClass", "midClass","smallClass", "subClass"];
  public columnDefs: TableColumnDef[] = [
    { columnId: 'productCdFv', header: "商品コード", width: 100, editable: true },
    { columnId: 'productNmFv', header: "商品名", width: 250 },
    { columnId: 'standardNmFv', header: "規格", width: 100 },
    { columnId: 'inventoryCountFv', header: "在庫数", width: 100, align: "right" },
    { columnId: 'costPriceFv', header: "POS原価（抜）", width: 100, align: "right"},
    { columnId: 'salePriceFv', header: "売価", width: 100, align: "right" },
    { columnId: 'mainClassFv', header: "大分類", width: 200 },
    { columnId: 'mediumClassFv', header: "中分類", width: 300 },
    { columnId: 'smallClassFv', header: "小分類", width: 300 },
    { columnId: 'subClassFv', header: "細分類", width: 300 }
  ];

  public qtyFormGroup: FormGroup;
  public checkGroup: FormGroup;
  userInfo: UserInfoDto[] = [];
  public stores: StoreDto[] = [];
  public storeList: any;
  public inventoryDataList: any;
  public nonInventoryDataList: [] = [];
  public categoryList: any;
  public gMainClassificationList: any;
  public gMediumClassificationList: any;
  public gSmallClassificationList: any;
  public gSubClassificationList: any;
  public displayColumns: string[] = ["isSelected", "menuGroupName", "menuName", "menuReadonly"];
  public loginStoreCd: any;
  public storeCd : any;
  public cmpCd: any; 
  public sysModeCd: any;
  public selectedStoreCd: any;
  public selectedStoreNm: any;
  public selectedIvDate: any;
  public isChecked: boolean;
  public status: any;
  public selectedStatus: any;
  public selectedDate: any;
  public isRedShow: boolean;
  public isDisabled: boolean;
  public selectedValue: any;
  public inputStatus: any;
  public orgStatus: any;
  public inventoryMonth: any;
  public inventoryProcessStatus: any;
  public inventoryDateFv: any;
  public nonIvCreateDateFv: any;
  public progStatus: any;
  public ivStatus: any;
  public searchIvStatus: any;
  public midMid: any;
  public midMain: any;
  public smallSmall: any;
  public smallMid: any;
  public smallMain: any;
  public subSub: any;
  public subSmall: any;
  public subMid: any;
  public subMain: any;
  public checkBoxIdList: string[] = [];
  public recordCount: number = 0;
  public searchPrgStatus: any;
  public ivMonthLists: any;
  public tmpIvMonth: any;
  public tmpIvPeriod: any;
  public tmpCondition: any;
  public tmpStatus: any;
  public searchExpandBtn: boolean;
  public expandBtn: boolean;
  stockErrorMessage: string;
  nonInvConfirmDto: NonInvConfirmDto[] = [];
  nonInvListDisplay = new MatTableDataSource<RspNonInvListInitDto>([]);
  nonInvLists: RspNonInvListInitDto[] = [];
  distinctDates: string[] = [];
  tmpDates: string[] = [];
  dates: any[] = [];
  dateLength: number;
  FUNCTIONID: string = "FPIV0009";
  maxLengthMsg = this.messageEntity.message[MessageID.ME100015].replace('%1', '-999999').replace('%2', '999999');
  startEndMsg = this.messageEntity.message[MessageID.ME100014];
  checkNumber = this.messageEntity.message[MessageID.ME100020];
  noInventoryConfirmMSG = this.messageEntity.message[MessageID.MQ100001];
  invScheduleId: any;
  searchedData: any;
  comeFromMenu: boolean = true;
  parentPage: string;
  searchData: Spiv00091SearchDto;
  fpiv0005SearchData: Spiv00051SearchDto;
  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService,
    private router: Router,
    public messageEntity: MessageService,

  ) { }

  ngOnInit() {
    this.storeCd = this.commonService.loginUser.realStoreCd;
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.searchExpandBtn = true;
    this.expandBtn = true;

    this.isRedShow = true;
    this.isDisabled = true;
    this.setDisplayCol();
    this.qtyFormGroup = this.fb.group({
      stockNumberFrom: ["", [this.integerLengthValidator(6)]],
      stockNumberTo: ["", [this.integerLengthValidator(6)]],
    },
      { validators: this.configCountValidator });
    this.qtyFormGroup.statusChanges.subscribe(() => {
      this.checkValues();
    });
    this.checkGroup = this.fb.group({
      checkboxes: this.fb.array([])
    });
    this.initFormGroup();
    this.initLists();

  }
  get checkboxes(): FormArray {
    return this.checkGroup.get('checkboxes') as FormArray;
  }

  async initLists(): Promise<void> {
  try {
      // Call from another screen or not
      if (this.commonService.fpiv0009ParentPage !== undefined) {
      this.comeFromMenu = false;
      this.parentPage = this.commonService.fpiv0009ParentPage;
      this.commonService.fpiv0009ParentPage = undefined;
      this.searchData = this.commonService.fpiv0009SearchData;
      this.commonService.fpiv0009SearchData = undefined;

      // Check search data is exist or not
      if (this.searchData != undefined) {
      // Load init data
      await this.getUserInfo();
      await this.initstoreList();
      await this.initIvScheduleMonth(this.searchData.storeCd);
      // await this.initIvDate();
      await this.initCategoryList();
      this.formGroup.get('storeCd').setValue(this.searchData.storeCd ?? "");
      this.formGroup.get('invScheduleId').setValue(this.searchData.invScheduleId ?? "");
      this.formGroup.get('productCd').setValue(this.searchData.productCd ?? "");
      this.formGroup.get('productNm').setValue(this.searchData.productNm ?? "");
      this.formGroup.get('gMainClassCd').setValue(this.searchData.gmainClassCd ?? "");
      this.formGroup.get('gMediumClassCd').setValue(this.searchData.gmidClassCd ?? "");
      this.formGroup.get('gSmallClassCd').setValue(this.searchData.gsmallClassCd ?? "");
      this.formGroup.get('gSubClassCd').setValue(this.searchData.gsubClassCd ?? "");
      this.qtyFormGroup.get('stockNumberFrom').setValue(this.searchData.stockQtyFrom ?? "");
      this.qtyFormGroup.get('stockNumberTo').setValue(this.searchData.stockQtyTo ?? "");
      this.tmpIvMonth = this.searchData.invYearMonth ?? "";
  }
    if (this.parentPage === 'fpiv0005') this.fpiv0005SearchData = this.commonService.fpiv0005SearchData;
    this.commonService.fpiv0005SearchData = undefined;
    
  } else {
    // Load init data
      await this.getUserInfo();
      await this.initstoreList();
      await this.initIvScheduleMonth(this.storeCd);
      // await this.initIvDate();
      await this.initCategoryList();

  }
    await this.initIvDate();
    this.doSearchNonIvData();
    this.commonService.closeSpinner();

    } catch (error) {
      this.commonService.closeSpinner();
      this.httpBasic.handleError(error);
    }
  }
 
  ngOnDestroy() {
    if (this.subscriptionFormGroup) this.subscriptionFormGroup.unsubscribe();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
  }

  initFormGroup() {
    this.formGroup = this.fb.group({
      storeCd: [this.storeCd, Validators.required],
      invScheduleId: [this.invScheduleId],
      invMonth: [this.tmpIvMonth],
      inventoryMonthTitle: ["", Validators.required],
      inventoryProcessStatus: ["", Validators.required],
      inventoryDate: [""],
      nonInventoryCreationDate: [""],
      productCd: [""],
      productNm: [""],
      gMainClassCd: [""],
      gMediumClassCd: [""],
      gSmallClassCd: [""],
      gSubClassCd: [""],
      qtyFormGroup: this.fb.group({
        stockNumberFrom: [""],
        stockNumberTo: [""],
        numOfInvFrom: [""],
        numOfInvTo: [""],
      }),
      checkFlag: [true]
    });

  }
  getUserInfo() :Promise<void>{
    return new Promise((resolve, reject) => {
      var request = {
        access: { ...this.commonService.loginUser },
      };
      let sub =this.httpBasic.generalRequest("GetUserInfo", request).subscribe(
        response => {
          sub.unsubscribe();
          this.receiveUserInfo(response);
          resolve();
        },
        error => {
          sub.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveUserInfo(response) {
    if (this.httpBasic.handleAppError(response)) return;
    this.userInfo = [];
    if (response.rows.length > 0) {
      this.userInfo = response.rows;
      this.cmpCd = this.userInfo[0].mainCmpnyCd;
      this.storeCd = this.userInfo[0].mainStoreCd;
      this.sysModeCd = this.userInfo[0].sysModeCd;
    }else{
      this.userInfo = [];
      this.cmpCd = "";
      this.storeCd = "";
      this.sysModeCd = "";
    }
  }


  initstoreList(): Promise<void> {
    return new Promise((resolve, reject) => {
      var request: InvMonthSearchDto = {
        access: { ...this.commonService.loginUser },
        cmpyCd: this.cmpCd,
        storeCd: this.storeCd,
        sysModeCd: this.sysModeCd

      };
      let subs = this.httpBasic.generalRequest("GetStoreList", request).subscribe(
        response => {
          subs.unsubscribe();
          this.receiveStoreList(response);
          resolve();
        },
        error => {
          subs.unsubscribe();
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }
  initIvScheduleMonth(storeCd: string): Promise<void> {
    return new Promise((resolve, reject) => {
      var request: InvMonthSearchDto = {
        storeCd: storeCd,
        cmpyCd: this.cmpCd,
        order: "1",
        access: { ...this.commonService.loginUser },
      };
      this.httpBasic.generalRequest("GetInvMonthList", request).subscribe(
        response => {
          this.receiveMonthList(response);
          resolve();
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  initIvDate(): Promise<void> {
    return new Promise((resolve, reject) => {
      var request: InvScheduleSearchDto = {
        invScheduleId : this.formGroup.get("invScheduleId").value,
        storeCd: this.formGroup.get("storeCd").value,
        cmpyCd: this.cmpCd,
        invMonth: this.tmpIvMonth,
        access: { ...this.commonService.loginUser },
      };
      this.httpBasic.generalRequest("GetInvScheduleList", request).subscribe(
        response => {
          this.receiveSchduleList(response);
          resolve();
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }


  initCategoryList(): Promise<void> {
    return new Promise((resolve, reject) => {
      var request = {
        access: { ...this.commonService.loginUser }
      };
      this.httpBasic.initCategoryList(request).subscribe(
        response => {
          this.checkResultCategory(response);
          resolve();
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }


  onScan(barcode: string) {
    let productCd = barcode;
    if (this.commonService.config.loginBarcode) {
      productCd = barcode.substring(
        this.commonService.config.loginBarcode.start,
        this.commonService.config.loginBarcode.end);
    }
    this.formGroup.get("productCd").setValue(productCd);
    this.getProductName(productCd);
  }

  onClickCellSupp(columnId: string, items: any,) {
    if (columnId === "productCdFv") {
      this.commonService.fpiv0009StoreCd = this.formGroup.get('storeCd').value;
      this.commonService.fpiv0009YearMonth = this.formGroup.get('invMonth').value;
      this.commonService.fpiv0009InvScheduleId = this.formGroup.get('invScheduleId').value;
      this.commonService.fpiv0005ParentPage = 'fpiv0009';
      this.commonService.pageMenuName = "棚卸入力";

      this.commonService.fpiv0005SearchData = {
        storeCd: this.formGroup.get("storeCd")?.value,
        invScheduleId: this.formGroup.get("invScheduleId")?.value,
        invYearMonth: this.tmpIvMonth,
        productCd: items.productCd,
        productNm: items.productNm,
        gmainClassCd: items.mainClassFv,
        gmidClassCd: items.mediumClassFv,
        gsmallClassCd: items.smallClassFv,
        gsubClassCd: items.subClassFv,
        inventoryQtyFrom: this.qtyFormGroup.get("numOfInvFrom")?.value,
        inventoryQtyTo: this.qtyFormGroup.get("numOfInvTo")?.value,
        stockQtyFrom: this.qtyFormGroup.get("stockNumberFrom")?.value,
        stockQtyTo: this.qtyFormGroup.get("stockNumberTo")?.value
      };
     
      this.commonService.fpiv0009SearchData = {
        storeCd: this.formGroup.get("storeCd")?.value,
        invScheduleId: this.formGroup.get("invScheduleId")?.value,
        invYearMonth: this.tmpIvMonth,
        productCd: this.formGroup.get("productCd")?.value,
        productNm: this.formGroup.get("productNm")?.value,
        gmainClassCd: this.formGroup.get("gMainClassCd")?.value,
        gmidClassCd: this.formGroup.get("gMediumClassCd")?.value,
        gsmallClassCd: this.formGroup.get("gSmallClassCd")?.value,
        gsubClassCd: this.formGroup.get("gSubClassCd")?.value,
        inventoryQtyFrom: this.qtyFormGroup.get("numOfInvFrom")?.value,
        inventoryQtyTo: this.qtyFormGroup.get("numOfInvTo")?.value,
        stockQtyFrom: this.qtyFormGroup.get("stockNumberFrom")?.value,
        stockQtyTo: this.qtyFormGroup.get("stockNumberTo")?.value
      }

      this.router.navigate(['/SPIV00051']);
    }
  }

  async fnOnChangeStoreCd() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    await this.initIvScheduleMonth(this.formGroup.get('storeCd').value);
    await this.initIvDate();
    this.commonService.closeSpinner();
  }

  async fnOnChangeSchedule() {
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.tmpIvMonth = this.getInventoryYearMonth();
    await this.initIvDate();
    this.commonService.closeSpinner();
  }

  fnOnChangeMainClassCd(event: any) {

    const selectedCd = event.target.value;
    this.formGroup.get('gMediumClassCd').setValue("");
    this.formGroup.get('gSmallClassCd').setValue("");
    this.formGroup.get('gSubClassCd').setValue("");
  }

  fnOnChangeMidClassCd(event: any) {

    const selectedCd = event.target.value;
    this.formGroup.get('gSmallClassCd').setValue("");
    this.formGroup.get('gSubClassCd').setValue("");
    this.gMediumClassificationList.forEach((data: any, i: any) => {
      if (selectedCd == data.gMiddleClassCdFv) {
        this.midMain = data.gMainClassCdFv;

        this.gMainClassificationList.forEach((element: any, i: any) => {
          if (this.midMain == element.gMainClassCdFv) {
            this.formGroup.get('gMainClassCd').setValue(this.midMain);
          }
        });
      }
    });

  }

  fnOnChangeSmallClassCd(event: any) {
    const selectedCd = event.target.value;
    this.formGroup.get('gSubClassCd').setValue("");
    this.gSmallClassificationList.forEach((data: any, i: any) => {
      if (selectedCd == data.gSmallClassCdFv) {
        this.smallMid = data.gMiddleClassCdFv;

        this.gMediumClassificationList.forEach((data: any, i: any) => {
          if (this.smallMid == data.gMiddleClassCdFv) {
            this.formGroup.get('gMediumClassCd').setValue(this.smallMid);
            this.smallMain = data.gMainClassCdFv;

            this.gMainClassificationList.forEach((data: any, i: any) => {
              if (this.smallMain == data.gMainClassCdFv) {
                this.formGroup.get('gMainClassCd').setValue(this.smallMain);
              }

            })
          }
        })
      }
    })
  }

  fnOnChangeSubClassCd(event: any) {

    const selectedCd = event.target.value;
    this.gSubClassificationList.forEach((data: any, i: any) => {
      if (selectedCd == data.gSubClassCdFv) {
        this.subSmall = data.gSmallClassCdFv;


        this.gSmallClassificationList.forEach((data: any, i: any) => {
          if (this.subSmall == data.gSmallClassCdFv) {
            this.formGroup.get('gSmallClassCd').setValue(this.subSmall);
            this.subMid = data.gMiddleClassCdFv;

            this.gMediumClassificationList.forEach((data: any, i: any) => {
              if (this.subMid == data.gMiddleClassCdFv) {
                this.formGroup.get('gMediumClassCd').setValue(this.subMid);
                this.subMain = data.gMainClassCdFv;

                this.gMainClassificationList.forEach((data: any, i: any) => {
                  if (this.subMain == data.gMainClassCdFv) {
                    this.formGroup.get('gMainClassCd').setValue(this.subMain);
                  }

                })


              }
            })
          }

        })
      }
    })
  }

  openBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(
      data => {
        if (data != undefined && data != null) {
          let productCd = data;
          let userid = data;
          if (this.commonService.config.loginBarcode) {
            productCd = data.substring(
              this.commonService.config.loginBarcode.start,
              this.commonService.config.loginBarcode.end);
          }
          this.formGroup.get("productCd").setValue(productCd);
          this.getProductName(productCd);
        }
      }
    )
  }


  doSearchNonIvData() {
    const input = this.tmpStatus;
    if (input !== "01" && this.qtyFormGroup.valid) {
      this.ivStatus = this.formGroup.get("checkFlag").value ? "0" : "1";
      var request: ReqNonInventoryDataSearch = {
        storeCdFv: this.formGroup.get("storeCd").value,
        cmpyCd: this.cmpCd,
        invScheduleIdFv:this.formGroup.get("invScheduleId").value,
        inventoryMonthFv: this.tmpIvMonth,
        inventoryProcessStatusFv: this.tmpStatus,
        inventoryDateFv: this.formGroup.get("inventoryDate").value,
        nonIvCreateDateFv: this.formGroup.get("nonInventoryCreationDate").value,
        gMainClassCdFv: this.formGroup.get("gMainClassCd").value,
        gMediumClassCdFv: this.formGroup.get("gMediumClassCd").value,
        gSmallClassCdFv: this.formGroup.get("gSmallClassCd").value,
        gSubClassCdFv: this.formGroup.get("gSubClassCd").value,
        productCdFv: this.formGroup.get("productCd").value,
        productNmFv: this.formGroup.get("productNm").value,
        stockNumberFromFv: this.qtyFormGroup.get("stockNumberFrom").value,
        stockNumberToFv: this.qtyFormGroup.get("stockNumberTo").value,
        checkflgFv: this.ivStatus,
        access: { ...this.commonService.loginUser }
      }

      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.httpBasic.generalRequest("NonInventoryConfirmationListSearch", request).subscribe(
        result => this.checkResultNonInventoryData(result),
        error => {
          this.httpBasic.handleError(error);
        }
      );
    }
  }

  doConfirm() {

    const nonInvConfirmUpdateArray = this.nonInvConfirmDto
      .filter(row => row.checkFlgFv !== '0')
      .map(row => ({
        invIdFv: row.invIdFv,
        checkFlgFv: row.checkFlgFv,
        functionId: this.FUNCTIONID
      }));


    if (nonInvConfirmUpdateArray.length < 1) {
      return;
    }

    var request: ReqNonInvConfirmEdit = {
      access: { ...this.commonService.loginUser },
      nonInvConfirmEdit: nonInvConfirmUpdateArray,
    }

    this.httpBasic.generalRequest("NonInventoryConfirmationListUpdate", request).subscribe(
      response => {
        this.updateResult(response);
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
      }
    );

    this.commonService.openSpinner("自動発注種別", "更新中・・・");
  }

  doClear() {

    this.formGroup.controls["productCd"].setValue('');
    this.formGroup.controls["productNm"].setValue('');
    this.qtyFormGroup.controls["stockNumberFrom"].setValue('');
    this.qtyFormGroup.controls["stockNumberTo"].setValue('');
    this.clearDropDown();
   
  }

  clearDropDown(){
    this.formGroup.controls["gMainClassCd"].setValue('');
    this.formGroup.controls["gMediumClassCd"].setValue('');
    this.formGroup.controls["gSmallClassCd"].setValue('');
    this.formGroup.controls["gSubClassCd"].setValue('');
  }
  receiveStoreList(response) {
    if (this.httpBasic.handleAppError(response)) return;

    this.stores = [];
    if(response.rows.length > 0){
      this.formGroup.get("storeCd").setValue(this.storeCd);
      this.stores = response.rows;
    }
    // else{
    //   this.status = "";
    //   this.processSts = "";
    //   this.actualInvDate = "";
    // }

    if (this.stores.length === 1) {
      this.formGroup.get("storeCd").disable();
    } else {
      this.formGroup.get("storeCd").enable();
    }
  }
  

  receiveMonthList(response : any) {
    if (this.httpBasic.handleAppError(response)) return;
    this.ivMonthLists = [];
    if (response.rows.length > 0) {
      this.ivMonthLists = response.rows;
      this.formGroup.get("invScheduleId").enable();
    }
    else {
      this.formGroup.get("invScheduleId").disable();
      this.ivMonthLists = [];
    }

    if (response.selected.length > 0) {
      this.formGroup.get("invScheduleId").setValue(response.selected[0].invScheduleId);
      this.tmpIvMonth = response.selected[0].invMonth;
      this.tmpIvPeriod = response.selected[0].invPeriod;
    }
    else {
      this.formGroup.get("invScheduleId").setValue("");
      this.tmpIvMonth = "";
      this.tmpIvPeriod = "";
    }

  }

  receiveSchduleList(response) {
    if (this.httpBasic.handleAppError(response)) return;

    if (response.rows.length > 0) {
      this.status = response.rows[0].invProgressSts;
      if (this.status == '02') {
        this.isRedShow = false;
        this.isDisabled = false;
      }
      else {
        this.isRedShow = true;
        this.isDisabled = true;
      }
      this.tmpCondition = response.rows[0].invProgressSts;
      if (this.tmpCondition == '01') {
        this.nonInvLists = [];
        this.nonInvListDisplay.data = [];
        this.pageChanged();
      }

      const uniqueDates = [...new Set(response.rows.map((row: any) => row.noInvCreateDate))];
      this.dates = uniqueDates.sort((a: any, b: any) => new Date(b).getTime() - new Date(a).getTime());

      this.formGroup.get("nonInventoryCreationDate").setValue(this.dates[0]);
      this.formGroup.get("inventoryProcessStatus").setValue(response.rows[0].processStatus);
      this.formGroup.get("inventoryDate").setValue(response.rows[0].actualInvDate);
      this.tmpStatus =  response.rows[0].invProgressSts;
      if (this.dates.length === 1) {
        this.formGroup.get("nonInventoryCreationDate").disable();
      } else {
        this.formGroup.get("nonInventoryCreationDate").enable();
      }
    }
  }

  receiveProductNm(response) {
    if (this.httpBasic.handleAppError(response)) return;
    if (response.rows.length < 1) {
      this.formGroup.get("productNm").setValue("");
      return;
    }
    this.formGroup.get("productNm").setValue(response.rows[0].productNm);
  }

  checkResultCategory(response: any) {
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    this.gMainClassificationList = response.gMainClassificationResult;
    this.gMediumClassificationList = response.gMediumClassificationResult;
    this.gSmallClassificationList = response.gSmallClassificationResult;
    this.gSubClassificationList = response.gSubClassificationResult;

  }

  checkResultNonInventoryData(response: RspSPIV00091Init) {
    this.clearProgressState();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    if (response.result.length === 0) {
      this.nonInvLists = [];
      this.nonInvListDisplay.data = [];
      return;
    }

    this.nonInvLists = [];
    if (this.checkboxes instanceof FormArray) {
      this.checkboxes.clear();
    }
    for (var data of response.result) {
      this.nonInvLists.push(data);
      const checkboxItem = { invIdFv: data.ivIdFv, checkFlg: data.checkFlgFv };
      this.checkboxes.push(this.fb.group(checkboxItem));
    }
    this.recordCount = this.nonInvLists?.length ?? 0;
    this.pageChanged();
  }

  getProductName(item: any): Promise<void> {
    return new Promise((resolve, reject) => {
      const request: ReqNonInventoryDataSearchProductNm = {
        productCd: item,
        cmpyCd: this.cmpCd,
        access: { ...this.commonService.loginUser },
      };
      this.httpBasic.generalRequest("GetProductName", request).subscribe(
        response => {
          this.receiveProductNm(response);
          this.doSearchNonIvData();
          resolve();
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  getInventoryYearMonth() {
    let index = this.ivMonthLists.findIndex(schedule => schedule.invScheduleId === this.formGroup.get("invScheduleId").value);
    return this.ivMonthLists[index].invMonth;
  }

  updateCheckBox(event: MatCheckboxChange, invIdFv: any): void {
    if (event.checked) {
      this.changeChkValue(invIdFv, '1');
      this.checkGroup.markAsDirty();
    } else {
      this.changeChkValue(invIdFv, '0');
      this.checkGroup.markAsPristine();

    }
  }

  updateChkBoxList(id: string, value: string) {
    if (id !== "" || id !== null) {
      const checkboxItem = { invSchId: id, checkFlgFv: value };
      this.checkboxes.push(this.fb.group(checkboxItem));
    }
  }


  changeChkValue(invIdFv: string, checkFlgFv: string): void {

    const existingItem = this.nonInvConfirmDto.find(item => item.invIdFv === invIdFv);
    if (existingItem) {
      existingItem.checkFlgFv = checkFlgFv;
    } else {
      this.nonInvConfirmDto.push({ invIdFv, checkFlgFv });
    }

  }

  changeSearchChk(event: MatCheckboxChange) {
    if (event.checked) {
      this.formGroup.get('checkFlag').setValue(true);
    }
    else {
      this.formGroup.get('checkFlag').setValue(false);
    }

  }

  chkSelectedValue(ivIdFv :any){
    const index = this.nonInvConfirmDto.find(item => item.invIdFv === ivIdFv && item.checkFlgFv == '1');
    if(index){
      return true;
    }
    else {
      return false;
    }
  }


  updateResult(rspNonInvConfirmEdit: RspNonInvConfirmEdit) {
    this.commonService.closeSpinner();
    if (rspNonInvConfirmEdit.fatalError && rspNonInvConfirmEdit.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog("自動発注対象", rspNonInvConfirmEdit.fatalError[0].errMsg);
      return;
    }
    if (rspNonInvConfirmEdit.normalError && rspNonInvConfirmEdit.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, rspNonInvConfirmEdit.normalError[0].errMsg);
      return;
    }

    this.nonInvConfirmDto.push(rspNonInvConfirmEdit.result);
    if (this.checkboxes instanceof FormArray) {
      this.checkboxes.clear();
    }
    for (const data of this.nonInvConfirmDto) {
      this.updateChkBoxList(data.invIdFv, data.checkFlgFv);
    }
   
    this.doSearchNonIvData();
  }

  checkValues() {
    const stockErrors = this.qtyFormGroup.errors;
    if (stockErrors?.greaterThanError) {
      this.stockErrorMessage = this.startEndMsg;
    } else {
      this.stockErrorMessage = '';
    }
  }


  integerLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value == null || value === '') {
        return null;
      }
      if (!/^-?\d*$/.test(value)) {
        return { integer: true };
      }
      const absValue = value.replace(/^[-+]/, '');
      if (absValue.length > maxLength) {
        return { maxLength: true };
      }
      return null;
    };
  }

  configCountValidator(control: AbstractControl): ValidationErrors | null {

    const stockNumberFrom = parseInt(control.get("stockNumberFrom")?.value);
    const stockNumberTo = parseInt(control.get("stockNumberTo")?.value);

    if (stockNumberFrom > stockNumberTo) {
      return { greaterThanError: true };
    }
    return null;
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    this.subscriptionInit = undefined;
  }

  setDisplayCol() {
    this.displayColumnDefs = [];
    this.displayColumnIds = ["checkFlgFv"];

    for (var colDef of this.columnDefs) {
      this.displayColumnDefs.push(colDef);
      this.displayColumnIds.push(colDef.columnId);
      this.calcTableWidth();
    }
  }
  
  calcTableWidth() {
    var width = 1;    
    width = width + 80 + 1 + 8;
    width = width + 80 + 1 + 8;            // For left border
    for (var colDef of this.displayColumnDefs) {
      width = width + colDef.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidth = { "width": "" + width + "px" };
  }


  styleFor(id: string) {
    return this.styleForBody(this.columnDefs, id);
  }


  styleForBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }
  

  styleForHeader(id: string) {
    return this.styleForHeaderBody(this.columnDefs, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }


  pageChanged() {
    let start;
    let end;
    this.nonInvListDisplay.data = [];
    this.recordCount = this.nonInvLists?.length ?? 0;
    if (this.matPaginator) {
      start = this.matPaginator.pageIndex * this.matPaginator.pageSize;
      end = start + this.matPaginator.pageSize;

      if (start >= this.recordCount) {
        this.matPaginator.pageIndex = Math.ceil(this.recordCount / this.matPaginator.pageSize) - 1;
        start = this.matPaginator.pageIndex * this.matPaginator.pageSize;
        end = start + this.matPaginator.pageSize;
      }
    }
    if (end > this.recordCount) {
      end = this.recordCount;
    }
    this.nonInvListDisplay.data = this.nonInvLists?.slice(start, end) ?? [];
  }

  lastPage() {
    this.matPaginator.pageIndex = Math.floor(this.recordCount / this.matPaginator.pageSize);
    this.pageChanged();
  }

  canDeactivate(): Observable<boolean> {
    if (!this.checkGroup.dirty) return of(true);
    return this.commonService.openYesNoDialog(this.commonService.pageTitle, this.noInventoryConfirmMSG);
  }

  getConfigChanges(): NonInvConfirmDto[] {
    let configs: NonInvConfirmDto[] = [];
    const checkboxesArray = this.checkGroup.get('checkboxes') as FormArray;
    checkboxesArray.controls.forEach((control: FormGroup) => {
      const invIdFv = control.get('invIdFv')?.value;
      const checkFlgFv = control.get('checkFlgFv')?.value;
      const functionId = control.get('functionId')?.value;
      let config: NonInvConfirmDto = {
        invIdFv: invIdFv,
        checkFlgFv: checkFlgFv,
        functionId: functionId,
      };
      configs.push(config);
    });
    return configs;
  }

  getMaxHeight(): string {
    if (this.searchExpandBtn && this.expandBtn) {
      return '310px';
    } else if (this.searchExpandBtn && !this.expandBtn) {
      return '500px';
    } else if (!this.searchExpandBtn && this.expandBtn || !this.searchExpandBtn && !this.expandBtn) {
      return '581px';
    }
  }

  getMinHeight(): string {
    return '310px';
  }

  getClassForColumn(columnId: string): string {
    if (columnId === 'productCdFv') {
      return 'custom-font-color';
    }
    return '';
  }

  getTitle() {
    this.expandBtn = this.sectionClassificationHeader.isSectionBodyOpen;
    return this.expandBtn ? "詳細条件を閉じる" : "詳細条件を表示";
  }

}

