import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { Spmt10301Dto } from 'src/app/response/rsp-spmt10301-search';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqSpmt10301GetDetail } from 'src/app/request/req-spmt10301-get-detail';
import { Spmt10301GetDetailDto, RspSpmt10301GetDetail } from 'src/app/response/rsp-spmt10301-get-detail';
import { AccessInfo } from 'src/app/page/spmt10301/spmt10301.component';
import { MatPaginator } from '@angular/material/paginator';

class Spmt10301DetailDialogConstant{
  public static readonly DELFLG_STATUS: string = '1';
  public static readonly MESSAGESEARCHING = '検索中・・・';
  public static readonly EMPTY_STRING = '';
  public static readonly REDBLACK_FLG_01 = '01';
}

interface Spmt10301TableColDef extends TableColumnDef {
  isNumber: boolean;
}

@Component({
  selector: 'app-spmt10301-detail-dialog',
  templateUrl: './spmt10301-detail-dialog.component.html',
  styleUrls: ['./spmt10301-detail-dialog.component.css']
})
export class Spmt10301DetailDialogComponent implements OnInit {

  public columnIdFirst: string[] = ["productCd","standardNm","outStockQty","redBlackFlgNm","reqStatusNm","inOrderPriceExc","inPosCostExc","inSellPriceExc","outReqDate","outApproveDate","outCrtDate","outCancelDate","slipDtlComment"]
  public columnIdSecond: string[] = ["productNm","inOrderAmt","inPosAmt","inSellAmt","inApproveDate"]

  public columnDefsItemsFirst: Spmt10301TableColDef[] = [
    {columnId: "productCd", header: "商品コード", width: 150, align: "left",rowspan: 1, isNumber: false},
    {columnId: "standardNm", header: "規格", width: 120, align: "left",rowspan: 1, isNumber: false},
    {columnId: "outStockQty", header: "出庫数量", width: 80, align: "right",rowspan: 1, isNumber: true},
    {columnId: "redBlackFlgNm", header: "赤黒区分", width: 120, align: "left",rowspan: 2, isNumber: false},
    {columnId: "reqStatusNm", header: "申請状態", width: 120, align: "left",rowspan: 2, isNumber: false},
    {columnId: "inOrderPriceExc", header: "発注単価", width: 120, align: "right",rowspan: 1, isNumber: true},
    {columnId: "inPosCostExc", header: "POS原価", width: 120, align: "right",rowspan: 1, isNumber: true},
    {columnId: "inSellPriceExc", header: "売価", width: 120, align: "right",rowspan: 1, isNumber: true},
    {columnId: "outReqDate", header: "出庫申請日", width: 120, align: "center",rowspan: 2, isNumber: false},
    {columnId: "outApproveDate", header: "出庫承認日", width: 120, align: "center",rowspan: 1, isNumber: false},
    {columnId: "outCrtDate", header: "出庫訂正日", width: 120, align: "center",rowspan: 2, isNumber: false},
    {columnId: "outCancelDate", header: "出庫取消日", width: 120, align: "center",rowspan: 2, isNumber: false},
    {columnId: "slipDtlComment", header: "詳細コメント", width: 120, align: "left",rowspan: 2, isNumber: false}
  ];

  public columnDefsItemsSecond: Spmt10301TableColDef[] = [
    {columnId: "productNm", header: "商品名称", width: 370, align: "left",rowspan: 1, colspan: 3, isNumber: false},
    {columnId: "inOrderAmt", header: "発注金額", width: 120, align: "right",rowspan: 1, colspan: 1, isNumber: true},
    {columnId: "inPosAmt", header: "POS金額", width: 120, align: "right",rowspan: 1, colspan: 1, isNumber: true},
    {columnId: "inSellAmt", header: "売価金額", width: 120, align: "right",rowspan: 1, colspan: 1, isNumber: true},
    {columnId: "inApproveDate", header: "入庫承認日", width: 120, align: "center",rowspan: 1, colspan: 1, isNumber: false}
  ]

  public itemTableWidth: any;
  public itemsShow: Spmt10301GetDetailDto[] = [];
  public items: Spmt10301GetDetailDto[] = [];
  public itemRecordCount: number = 0;
  public outStoreNm: string;
  public inStoreNm: string;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spmt10301DetailDialogComponent>,
    public httpBasic: HttpBasicService,
    @Inject(MAT_DIALOG_DATA) public data: {row: Spmt10301Dto, accessInfo: AccessInfo},
    public commonService: CommonService) { }

  ngOnInit() {
    this.calcTableWidth();
    this.getDetailPrc();
  }

  calcTableWidth() {
    let width = 1
    for (let colDef of this.columnDefsItemsFirst) {
      width = width + colDef.width + 1 + 8;
    }
    this.itemTableWidth = {"width": "" + width + "px"};
  }

  getDetailPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, Spmt10301DetailDialogConstant.MESSAGESEARCHING);
    const request: ReqSpmt10301GetDetail = {
      slipNo: this.data.row.slipNo,
      access: this.data.accessInfo
    } 
    const subsc = this.httpBasic.getDetailSpmt10301(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpmt10301GetDetail) {
    this.commonService.closeSpinner();
    if(this.httpBasic.handleAppError(response)){
      return;
    }

    this.itemRecordCount = response.results.length ?? 0;
    response.results.forEach((item: Spmt10301GetDetailDto) => {
      this.items.push(item)
    })

    this.pageChanged();
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0){
      this.pageChanged();
    }
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
  }

  colStyle(item: Spmt10301GetDetailDto){
    if(item.delflg === Spmt10301DetailDialogConstant.DELFLG_STATUS){
      return 'border-right-gray bg-gray'
    }
    if(item.redBlackFlg !== Spmt10301DetailDialogConstant.REDBLACK_FLG_01){
      return 'text-color-red';
    }
    return Spmt10301DetailDialogConstant.EMPTY_STRING;
  }
}
