import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ReqSpmt10271GetDetail } from 'src/app/request/req-spmt10271-detail';
import { Spmt10271GetDetailDto, RspSpmt10271GetDetail } from 'src/app/response/rsp-spmt10271-get-detail';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { CommonService } from 'src/app/service/common.service';
import { Constant } from 'src/app/page/spmt10271/spmt10271-approval-constant';
import { Spmt10271Dto } from 'src/app/response/rsp-spmt10271-search';
import { ReqAccess } from 'src/app/request/req-access';
import { MatPaginator } from '@angular/material/paginator';
import { MessageService } from "src/app/service/message.service";
import * as ConstMessageID from 'src/app/common/const-message-id';

interface IMOATableDeTail extends TableColumnDef {
  color?: string;
  numberFlg?: boolean;
}

@Component({
  selector: 'spmt10271-detail-dialog',
  templateUrl: './spmt10271-detail-dialog.component.html',
  styleUrls: ['./spmt10271-detail-dialog.component.css']
})

export class Spmt10271DetailDialogComponent implements OnInit {

  access: ReqAccess | any = {
    ...this.commonService.loginUser,
    cmpnyCd: Constant.EMPTY_STRING,
    lang: Constant.EMPTY_STRING,
    storeCd: Constant.EMPTY_STRING,
  };

  //Message
  checkQtyMsg = this.messageEntity.message[ConstMessageID.ME200011];

  public itemTableWidth: any;
  public paginatorMaxWidth: any;
  public items: any = [];
  public itemsShow: any = [];
  public itemRecordCount: number = 0;
  public OUTSTORECD: string;
  public OUTSTORENM: string;
  public INSTORECD: string;
  public INSTORENM: string;
  public slipNo: string;
  public productCd: string;
  public productNm: string;
  public specification: string;
  public outBoundQty: number;
  public orderPriceExc: number;
  public stkInPosPrice: number;
  public stkInPrice: number;
  public oderAmt: number;
  public stkInPosAmt: number;
  public stkInAmt: number;
  public redBlackFlg: string;
  public reqStatus: string;
  public departureApplicationDate: string;
  public issueApprovalDate: string;
  public outputCorrectionDate: string;
  public outCancelDate: string;
  public commentHeader: string;
  public systemMessage: string;
  public warehousingApprovalDate: string;

  public columnIdFirst: string[] = ["productCd", "specification", "outBoundQty", "redBlackFlg", "reqStatus", "orderPriceExc", "stkInPosPrice",
    "stkInPrice", "departureApplicationDate", "issueApprovalDate", "outputCorrectionDate", "outCancelDate", "commentHeader", "systemMessage"]
  public columnIdSecond: string[] = ["productNm", "oderAmt", "stkInPosAmt", "stkInAmt", "warehousingApprovalDate"]

  public columnDefsItemsFirst: IMOATableDeTail[] = [
    { columnId: "productCd", header: "商品コード", width: 90, align: "left", rowspan: 1 },
    { columnId: "specification", header: "規格", width: 80, align: "left", rowspan: 1 },
    { columnId: "outBoundQty", header: "出庫数量", width: 70, align: "right", rowspan: 1 },
    { columnId: "redBlackFlg", header: "赤黒区分", width: 55, align: "left", rowspan: 2 },
    { columnId: "reqStatus", header: "申請状態", width: 70, align: "left", rowspan: 2 },
    { columnId: "orderPriceExc", header: "発注単価", width: 80, align: "right", rowspan: 1, numberFlg: true },
    { columnId: "stkInPosPrice", header: "POS原価", width: 80, align: "right", rowspan: 1, numberFlg: true },
    { columnId: "stkInPrice", header: "売価", width: 80, align: "right", rowspan: 1, numberFlg: true },
    { columnId: "departureApplicationDate", header: "出庫申請日", width: 80, align: "center", rowspan: 2 },
    { columnId: "issueApprovalDate", header: "出庫承認日", width: 80, align: "center", rowspan: 1 },
    { columnId: "outputCorrectionDate", header: "出庫訂正日", width: 80, align: "center", rowspan: 2 },
    { columnId: "outCancelDate", header: "出庫取消日", width: 80, align: "center", rowspan: 2 },
    { columnId: "commentHeader", header: "詳細コメント", width: 250, align: "left", rowspan: 2 },
    { columnId: "systemMessage", header: "システムメッセージ", width: 250, align: "left", rowspan: 2, color: "red" },
  ];
  public columnDefsItemsSecond: IMOATableDeTail[] = [
    { columnId: "productNm", header: "商品名称", width: 240, align: "left", rowspan: 1, colspan: 3 },
    { columnId: "oderAmt", header: "発注金額", width: 80, align: "right", rowspan: 1, colspan: 1, numberFlg: true },
    { columnId: "stkInPosAmt", header: "POS金額", width: 80, align: "right", rowspan: 1, colspan: 1, numberFlg: true },
    { columnId: "stkInAmt", header: "売価金額", width: 80, align: "right", rowspan: 1, colspan: 1, numberFlg: true },
    { columnId: "warehousingApprovalDate", header: "入庫承認日", width: 80, align: "center", rowspan: 1, colspan: 1 },
  ]

  @ViewChild("matPagenatorItem1", { static: false }) matPagenatorItem: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spmt10271DetailDialogComponent>,
    public httpBasic: HttpBasicService,
    private messageEntity: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: { row: Spmt10271Dto },
    public commonService: CommonService) {
  }

  ngOnInit() {
    this.calcTableWidth();
    this.fnGetAccessInfo();
  }

  fnGetAccessInfo() {
    let req = {
      access: this.access
    };
    this.httpBasic.getUserInfo(req).subscribe((res) => {
      if (this.httpBasic.handleAppError(res)) return;
      if (res.rows.length > 0) {
        this.access.cmpnyCd = res.rows[0].mainCmpnyCd;
        this.access.storeCd = res.rows[0].mainStoreCd;
        this.access.sysModeCd = res.rows[0].sysModeCd;
        this.access.lang = res.rows[0].mainLang;
        this.getDetailPrc();
      }
    });
  }

  getDetailPrc() {
    this.commonService.openSpinner(this.commonService.pageTitle, Constant.MESSAGESEARCHING);
    const request: ReqSpmt10271GetDetail = {
      slipNo: this.data.row.slipNo,
      storeCd: this.data.row.outStoreCd,
      updateTime: this.access.updDatetime,
      access: this.access,
    }
    const subsc = this.httpBasic.getDetailSpmt10271(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpmt10271GetDetail) {
    this.commonService.closeSpinner();
    if (this.httpBasic.handleAppError(response)) {
      return;
    }

    this.items = this.getResponse(response);
    this.slipNo = this.data.row.slipNo;
    this.OUTSTORENM = this.data.row.outStoreNm;
    this.INSTORECD = this.data.row.inStoreCd;
    this.INSTORENM = this.data.row.inStoreNm;
    this.itemRecordCount = this.items.length ?? 0;
    this.pageChanged();
  }

  getResponse(response: RspSpmt10271GetDetail) {
    let searchResult = response.results;
    return searchResult.map((x: Spmt10271GetDetailDto) => ({
      ...x,
      systemMessage: x.systemMessage === Constant.CHECK_SYSTEM_MESSAGE_FLG ?
       this.checkQtyMsg.replace(/%1/g, Constant.LOGICSTOCKQTY_FIELD).replace(/%2/g, Constant.OUTSTOCKQTY_FIELD) : Constant.EMPTY_STRING
    }))
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0) {
      this.pageChanged();
    }
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
  }

  calcTableWidth() {
    var width = 1
    for (var colDef of this.columnDefsItemsFirst) {
      width = width + colDef.width + 1 + 8;
    }
    this.itemTableWidth = { "width": Constant.EMPTY_STRING + width + "px" };
    this.paginatorMaxWidth = { "max-width": Constant.EMPTY_STRING + width + "px" };
  }
}
