import { Component, OnInit, Inject, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { Spmt10291Dto } from 'src/app/response/rsp-spmt10291-search';
import { ReqSpmt10291Confirm } from 'src/app/request/req-spmt10291-confirm';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ErrorNotificationDialogComponent } from '../error-notification-dialog/error-notification-dialog.component';
import { Subscription } from 'rxjs';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { ReqAccess } from 'src/app/request/req-access';
import { Req } from 'src/app/request/req';
import { MatPaginator } from '@angular/material/paginator';
import { Rsp } from 'src/app/response/rsp';
import { MessageService } from 'src/app/service/message.service';
import { MI200002, MQ200003 } from 'src/app/common/const-message-id';

class IMIAConfirmDialogConstant{
  public static readonly messageSearching: string = '検索中・・・';
  public static readonly EMPTY_STRING: string = '';
}

interface IMIAConfirmDialogTableColumnDef extends TableColumnDef{
  numberFlg?: boolean;
}

class ItemSelect {
  checkStatusFlg: string;
  slipNo: string;
  outStoreCd: string;
  outStoreNm: string;
  reqStatus: string;
  reqStatusNm: string;
  totalInStockQty: string;
  totalInOrderPriceExc: string;
  slipCreateDate: string;
  outRequesterCd: string;
  outApproverCd: string;
  inApproverCd: string;
  slipComment: string;
  approverComment: string;
  updDatetime: string;
  delFlg: string;
  redBlackFlg: string;
}

@Component({
  selector: 'app-spmt10291-confirm-dialog',
  templateUrl: './spmt10291-confirm-dialog.component.html',
  styleUrls: ['./spmt10291-confirm-dialog.component.css']
})
export class Spmt10291ConfirmDialogComponent implements OnInit, AfterViewInit {
  access:ReqAccess | any = {
    ...this.commonService.loginUser,
    cmpnyCd:IMIAConfirmDialogConstant.EMPTY_STRING,
    lang: IMIAConfirmDialogConstant.EMPTY_STRING,
    storeCd: IMIAConfirmDialogConstant.EMPTY_STRING,
  };


  public columnId: string[] = ["slipNo","outStoreCd","outStoreNm","reqStatusNm","totalInStockQty","totalInOrderPriceExc","slipCreateDate","outRequesterNm","outApproverNm","inApproverNm","slipComment","approverComment"]

  public columnDefsItems: IMIAConfirmDialogTableColumnDef[] = [
    {columnId: "slipNo", header: "伝票番号", width: 100, align: "left", editable: true},
    {columnId: "outStoreCd", header: "出庫店舗コード", width: 120, align: "left"},
    {columnId: "outStoreNm", header: "出庫店舗名称", width: 120, align: "left"},
    {columnId: "reqStatusNm", header: "申請状態", width: 100, align: "left"},
    {columnId: "totalInStockQty", header: "入庫数量合計", width: 120, align: "right",  numberFlg: true},
    {columnId: "totalInOrderPriceExc", header: "入庫原価金額合計", width: 120, align: "right", numberFlg: true},
    {columnId: "slipCreateDate", header: "伝票作成日", width: 100, align: "center"},
    {columnId: "outRequesterNm", header: "出庫申請者", width: 100, align: "left"},
    {columnId: "outApproverNm", header: "出庫承認者", width: 100, align: "left"},
    {columnId: "inApproverNm", header: "入庫承認者", width: 100, align: "left"},
    {columnId: "slipComment", header: "出庫申請者コメント", width: 110, align: "left"},
    {columnId: "approverComment", header: "出庫承認者コメント", width: 110, align: "left"},
  ];

  public itemsShow: ItemSelect[] = [];
  public items: ItemSelect[] = [];
  public itemTableWidth: any;
  public itemRecordCount: number = 0;
  private subcriptionYesNoDialog: Subscription;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spmt10291ConfirmDialogComponent>,
    private message: MessageService,
    public httpBasic: HttpBasicService,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: {searchResult: Spmt10291Dto[]},
    public commonService: CommonService) { }

  ngOnInit() {
    this.calcTableWidth();
    this.fnGetAccessInfo();
  }

  ngAfterViewInit() {
    this.initTable();
    this.changeDetectorRef.detectChanges();
  }

  initTable(){
    this.data.searchResult.forEach((item: Spmt10291Dto) => {
      this.items.push({
        ...item
      })
    })

    this.itemRecordCount = this.data.searchResult.length ?? 0;
    this.pageChanged();
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0){
      this.pageChanged();
    }
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
  }

  fnGetAccessInfo(){
    let req: Req = {
      access: this.access
    };
    this.httpBasic.getUserInfo(req).subscribe((res) =>{
      if (this.httpBasic.handleAppError(res)) return;
      if (res.rows.length > 0) {
        this.access.cmpnyCd = res.rows[0].mainCmpnyCd;
        this.access.storeCd = res.rows[0].mainStoreCd;
        this.access.sysModeCd = res.rows[0].sysModeCd;
        this.access.lang = res.rows[0].mainLang;
      }
    })
  }
  calcTableWidth() {
    let width = 1
    for (let colDef of this.columnDefsItems) {
      width = width + colDef.width + 1 + 8;
    }
    this.itemTableWidth = {"width": "" + width + "px"};
  }

  confirmBtn(){
    this.subcriptionYesNoDialog = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200003]).subscribe(data => {
      if(data) this.confirmPrc();
      this.subcriptionYesNoDialog.unsubscribe();
    })
  }

  getRequestData (){
    return this.items.map((item: ItemSelect) => ({
      ...item
    }));
  }

  confirmPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, IMIAConfirmDialogConstant.messageSearching);
    const request: ReqSpmt10291Confirm = {
      rows: this.getRequestData(),
      access: this.access
    }
    const subsc = this.httpBasic.confirmSpmt10291(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: Rsp) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });
      const subcription = dialogRef.afterClosed().subscribe(() => {
        this.dialogRef.close(true);
        subcription.unsubscribe();
      });
      return;
    }

    const dialogRef = this.commonService.dialog.open(NotificationDialogComponent, {
      data: {title: this.commonService.pageTitle, message: this.message.message[MI200002]}
    });
    const subcription = dialogRef.afterClosed().subscribe(() => {
      this.dialogRef.close(true);
      subcription.unsubscribe();
    });
  }
}
