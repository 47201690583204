import { ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { NgControl } from "@angular/forms";

export interface PositiveNumberOnlyError {
  errorValue: any;
  minValue?: boolean;
  maxValue?: boolean;
  optionMaxValue?: boolean;
}

@Directive({
  selector: "[appPositiveNumberOnly]",
})
export class PositiveNumberOnlyDirective implements OnChanges {
  private el: NgControl;

  @Input() minValue: number;
  @Input() maxValue: number;
  @Input() optionMaxValue: number;
  @Input() currentValue: number;
  @Output() appPositiveNumberError = new EventEmitter<PositiveNumberOnlyError>();

  constructor(ngControl: NgControl, private cdr: ChangeDetectorRef) {
    this.el = ngControl;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentValue && !changes.currentValue.firstChange) {
      let value = changes.currentValue?.currentValue?.toString();
      let previousValue = changes.currentValue?.previousValue?.toString();
      const minValue = this.minValue;
      const maxValue = this.maxValue;
      const optionMaxValue = this.optionMaxValue;
      let minError: boolean = false;
      let maxError: boolean = false;
      let optionMaxError: boolean = false;

      let sanitizedValue = value.replace(/[^0-9]/g, "");

      let numericValue = Number(sanitizedValue);
      if (minValue !== undefined && numericValue < minValue) {
        numericValue = previousValue;
        minError = true;
      } else if (maxValue !== undefined && numericValue > maxValue) {
        numericValue = previousValue;
        maxError = true;
      }
      if (optionMaxValue !== undefined && numericValue > optionMaxValue) {
        numericValue = previousValue;
        optionMaxError = true;
      }
      this.el.control.patchValue(numericValue);
      this.cdr.detectChanges();
      if (minError || maxError || optionMaxError) {
        this.appPositiveNumberError.emit({
          errorValue: value,
          minValue: minError,
          maxValue: maxError,
          optionMaxValue: optionMaxError,
        });
      }
    }
  }
}
