import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { NumberKeypadComponent } from 'src/app/partsCommon/number-keypad/number-keypad.component';
import { ReqSpvw10231Init } from 'src/app/request/req-spvw10231-init';
import { ReqSpvw10231Search } from 'src/app/request/req-spvw10231-search';
import { DropdownListDto, PartnerDto, RspSpvw10231Init, UserDto } from 'src/app/response/rsp-spvw10231-init'
import { Spvw10231Dto, RspSpvw10231Search } from 'src/app/response/rsp-spvw10231-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { BarcodeDialogComponent } from 'src/app/dialog/barcode-dialog/barcode-dialog.component';
import { ErrorNotificationDialogComponent } from 'src/app/dialog/error-notification-dialog/error-notification-dialog.component';
import { RspUserInfo, UserInfoDto } from 'src/app/response/rsp-user-info';
import { ReqUserInfoSearch } from 'src/app/request/req-user-info-search';
import { RspGetStoreList, GetStoreListDto } from 'src/app/response/rsp-get-store-list';
import { ReqGetStoreList } from 'src/app/request/req-get-store-list';
import { MI100001, ME200012, MI200003, MQ200010 } from "src/app/common/const-message-id";
import { ReqAccess } from 'src/app/request/req-access';
import { MessageService } from 'src/app/service/message.service';
import { ReqPageDto } from 'src/app/request/req-page-dto';

class Spvw10231Constant {
  public static readonly MAXITEMS: number = 1000;
  public static readonly DATEFORMAT: string = 'yyyy-MM-dd';
  public static readonly EMPTY_STRING: string = '';
  public static readonly MESSAGE_LOADING: string = '検索中・・・';
  public static readonly OFFICE_INPUT_FLG_CTRL = 'officeInputFlg';
  public static readonly ORDER_DATE_FROM_CTRL = 'orderDateFrom';
  public static readonly ORDER_DATE_TO_CTRL = 'orderDateTo';
  public static readonly STORECD_CTRL: string = 'storeCd';
  public static readonly PRODUCTCD_CTRL: string = 'productCd';
  public static readonly PARTNERCD_CTRL: string = 'partnerCd';
  public static readonly STORE_IN_CHARGE_CTRL: string = 'storeInCharge';
  public static readonly PRODUCT_REQ_QTY_CTRL: string = 'productReqQty';
  public static readonly MISSING_PRODUCT_REQ_QTY_CTRL: string = 'missingProductReqQty';
  public static readonly PRODUCT_ERR_QTY_CTRL: string = 'productErrQty';
  public static readonly OFFICE_INPUT_FLG_JP: string = '発注種別';
  public static readonly ZERO = 0;
  public static readonly LIMIT_RECORD = 1001;
}

interface AccessInfo extends ReqAccess {
  cmpnyCd?: string;
  lang?: string;
  sysModeCd?: string;
}

interface Spvw10231TableColDef extends TableColumnDef {
  isNumber: boolean;
}

@Component({
  selector: "app-spvw10231",
  templateUrl: "./spvw10231.component.html",
  styleUrls: ["./spvw10231.component.css"]
})
export class Spvw10231Component implements OnInit, OnDestroy {
  public itemsShow: Spvw10231Dto[] = [];
  public items: Spvw10231Dto[] = [];
  public itemRecordCount: number = 0;
  public columnIds: string[] = ["orderDate","store","product","standardNm","partner","orderRequestQty","orderUnitPrice","orderPrice","manager","officeInputNm"];

  public columnDefsItems: Spvw10231TableColDef[] = [
    {columnId: "orderDate", header: "発注日", width: 80, align: "center", isNumber: false},
    {columnId: "store", header: "店舗", width: 200, align: "left", isNumber: false},
    {columnId: "product", header: "商品", width: 300, align: "left", isNumber: false},
    {columnId: "standardNm", header: "規格", width: 100, align: "left", isNumber: false},
    {columnId: "partner", header: "取引先", width: 250, align: "left", isNumber: false},
    {columnId: "orderRequestQty", header: "発注数", width: 80, align: "right", isNumber: true},
    {columnId: "orderUnitPrice", header: "発注単価", width: 100, align: "right", isNumber: true},
    {columnId: "orderPrice", header: "発注金額", width: 100, align: "right", isNumber: true},
    {columnId: "manager", header: "店舗担当者", width: 200, align: "left", isNumber: false},
    {columnId: "officeInputNm", header: "発注種別", width: 80, align: "left", isNumber: false}
  ];

  public userInfo: UserInfoDto = null;
  public accessInfo: AccessInfo = {
    ...this.commonService.loginUser
  }
  public storeCdList: GetStoreListDto[] = [];
  public partnerCdList: PartnerDto[] = [];
  public userCdList: UserDto[] = [];
  public officeInputFlgList: DropdownListDto[] = [];
  public tableWidth: any;
  public isDisableSrchBtn: boolean = false;

  public searchCondition: FormGroup;
  public searchResult: FormGroup;
  private searchConditionSubscription: Subscription;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  @ViewChild("keypad", {static: true}) numberKeypadComponent: NumberKeypadComponent;
  constructor(
    public commonService: CommonService,
    public httpBasic: HttpBasicService,
    private message: MessageService,
    private fb: FormBuilder,
    private fbDetail: FormBuilder
  ) {}

  ngOnInit(): void {
    this.commonService.pageTitle = "発注予定照会";
    this.commonService.config.use10KeyPad = true;
    // this.commonService.pageTitle = this.commonService.pageMenuName;
    this.searchCondition = this.fb.group({
      orderDateFrom: [this.getPreviousDay(),Validators.required],
      orderDateTo: [new Date(),Validators.required],
      storeCd: [Spvw10231Constant.EMPTY_STRING,Validators.required],
      partnerCd: [Spvw10231Constant.EMPTY_STRING],
      productCd: [Spvw10231Constant.EMPTY_STRING],
      storeInCharge: [Spvw10231Constant.EMPTY_STRING],
      officeInputFlg: new FormArray([], [Validators.required])
    });

    this.searchResult = this.fbDetail.group({
      productReqQty: [{value: Spvw10231Constant.EMPTY_STRING, disabled: true}],
      missingProductReqQty: [{value: Spvw10231Constant.EMPTY_STRING, disabled: true}],
      productErrQty: [{value: Spvw10231Constant.EMPTY_STRING, disabled: true}]
    })
    this.calcTableWidth();
    this.initPrc();
    this.set10KeyPadTarget();
    this.searchConditionValueChange();
  }

  getPreviousDay(){
    const oneWeekInMilisecond = 6 * 24 * 60 * 60 * 1000;
    const miliseconds = new Date().getTime() - oneWeekInMilisecond;

    return new Date(miliseconds);
  }

  async initPrc() {
    try{
      this.commonService.openSpinner(this.commonService.pageTitle, Spvw10231Constant.MESSAGE_LOADING);
      await this.getUserInfo();
      await this.getStoreList();
      await this.getInitPrc();
      this.commonService.closeSpinner();
    } catch(err) {
      this.commonService.closeSpinner();
    }
  }

  getUserInfo() {
    let request: ReqUserInfoSearch = {
      access: this.accessInfo
    }
    return new Promise<void>((resolve,reject) => {
      let subsc = this.httpBasic.getUserInfo(request).subscribe(
        result => {
          this.setResultGetUserInfo(result);
          subsc.unsubscribe();
          resolve();
        },
        error => {
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
          reject();
        }
      )
    })
  }

  setResultGetUserInfo(response: RspUserInfo) {
    if (this.httpBasic.handleAppError(response)) return;
    if (response.rows.length > 0) {
      this.userInfo = response.rows[0];
      this.accessInfo = {
        ...this.commonService.loginUser,
        cmpnyCd: response.rows[0].mainCmpnyCd,
        lang: response.rows[0].mainLang,
        sysModeCd: response.rows[0].sysModeCd
      }
    }
  }

  getStoreList() {
    let request: ReqGetStoreList = {
      access: this.accessInfo,
      storeCd: Spvw10231Constant.EMPTY_STRING,
      userId: Spvw10231Constant.EMPTY_STRING,
      cmpyCd: this.accessInfo.cmpnyCd,
      sysModeCd: this.accessInfo.sysModeCd
    }

    return new Promise<void>((resolve,reject) => {
      let subsc = this.httpBasic.getStoreList(request).subscribe(
        result => {
          this.setResultGetStoreList(result);
          subsc.unsubscribe();
          resolve();
        },
        error => {
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
          reject();
        }
      )
    })
  }

  setResultGetStoreList(response: RspGetStoreList) {
    if (this.httpBasic.handleAppError(response)) return;
    this.storeCdList = response.rows;
    this.searchCondition.get(Spvw10231Constant.STORECD_CTRL).setValue(this.userInfo.mainStoreCd);
  }

  getInitPrc() {
    let request: ReqSpvw10231Init = {
      access: this.accessInfo
    }

    return new Promise<void>((resolve,reject) => {
      let subsc = this.httpBasic.initSpvw10231Init(request).subscribe(
        result => {
          this.setResultInit(result);
          subsc.unsubscribe();
          resolve();
        },
        error => {
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
          reject();
        }
      )
    })
  }

  getPageDto():ReqPageDto {
    return {
      pageNum: Spvw10231Constant.ZERO,
      dispNum: Spvw10231Constant.LIMIT_RECORD
    }
  }

  setResultInit(result: RspSpvw10231Init) {
    if (this.httpBasic.handleAppError(result)) return;
    this.officeInputFlgList = result.dropDownList;
    this.partnerCdList = result.partnerList;
    this.userCdList = result.userList;
    const officeInputFlg = this.searchCondition.get(Spvw10231Constant.OFFICE_INPUT_FLG_CTRL) as FormArray;
    this.officeInputFlgList.forEach(() => {
      officeInputFlg.push(new FormControl(true))
    })
  }

  calcTableWidth() {
    let width = 1;
    for (let colDef of this.columnDefsItems) {
      width = width + colDef.width + 1 + 8;
    }
    this.tableWidth = { "width": "" + width + "px" };
  }

  searchConditionValueChange() {
    this.searchConditionSubscription = this.searchCondition.valueChanges.subscribe(() => {
      this.updateButtonState();
    })
  }

  updateButtonState() {
    this.isDisableSrchBtn = !this.searchCondition.valid;
  }

  pageChangedItem() {
    if(this.itemsShow?.length > 0) this.pageChanged();
  }

  clearSearchResult() {
    this.items = [];
    this.itemsShow = [];
    this.itemRecordCount = 0;
    this.matPagenatorItem._changePageSize(25);
    this.searchResult.reset({
      productReqQty: Spvw10231Constant.EMPTY_STRING,
      missingProductReqQty: Spvw10231Constant.EMPTY_STRING,
      productErrQty: Spvw10231Constant.EMPTY_STRING
    })
  }

  clearBtn() {
    if(this.items.length > 0){
      let subcription = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200010]).subscribe((data: boolean) => {
        if(data) this.clearPrc();
        subcription.unsubscribe();
      })
      return;
    }
    this.clearPrc();
  }

  clearPrc(){
    this.searchCondition.reset({
      orderDateFrom: this.getPreviousDay(),
      orderDateTo: new Date(),
      storeCd: this.userInfo.mainStoreCd,
      partnerCd:Spvw10231Constant.EMPTY_STRING,
      productCd: Spvw10231Constant.EMPTY_STRING,
      storeInCharge: Spvw10231Constant.EMPTY_STRING
    })

    const officeInputFlgList = this.searchCondition.get(Spvw10231Constant.OFFICE_INPUT_FLG_CTRL) as FormArray;
    officeInputFlgList.controls.forEach((item: FormControl) => {
      item.setValue(true)
    })

    this.numberKeypadComponent.setTargetForm(this.searchCondition.get(Spvw10231Constant.PRODUCTCD_CTRL) as FormControl);
    this.set10KeyPadTarget();
    this.clearSearchResult();
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
    let id = "item-table-box";
    document.getElementById(id)?.scrollTo(0, 0);
  }

  getDate(ctrlNm: string) {
    if(this.searchCondition.get(ctrlNm).value === null) return Spvw10231Constant.EMPTY_STRING;
    return formatDate(this.searchCondition.get(ctrlNm).value, Spvw10231Constant.DATEFORMAT, 'en_US');
  }

  getReqStatus(){
    let officeInputFlgLs = [];
    const officeInputFlg = this.searchCondition.get(Spvw10231Constant.OFFICE_INPUT_FLG_CTRL) as FormArray;
    officeInputFlg.controls.forEach((item: FormControl, index: number) => {
      if(item.value){
        officeInputFlgLs.push(this.officeInputFlgList[index].DATACD)
      }
    })
    return officeInputFlgLs.toString();
  }

  officeInputFlgIsNull(){
    const officeInputFlgLs = this.searchCondition.get(Spvw10231Constant.OFFICE_INPUT_FLG_CTRL) as FormArray;
    if(officeInputFlgLs.controls.every((item: FormControl) => !item.value)){
      this.commonService.openErrorDialog(this.commonService.pageTitle, this.message.message[ME200012].replace('%1', Spvw10231Constant.OFFICE_INPUT_FLG_JP));
      this.isDisableSrchBtn = true;
      return true;
    }
    return false;
  }

  searchClickBtn() {
    if(this.officeInputFlgIsNull()) return;
    this.clearSearchResult();
    this.searchPrc();
  }

  searchPrc() {
    this.commonService.openSpinner(this.commonService.pageTitle, Spvw10231Constant.MESSAGE_LOADING);
    const request: ReqSpvw10231Search = {
      access: this.accessInfo,
      orderDateFrom: this.getDate(Spvw10231Constant.ORDER_DATE_FROM_CTRL),
      orderDateTo: this.getDate(Spvw10231Constant.ORDER_DATE_TO_CTRL),
      storeCd: this.searchCondition.get(Spvw10231Constant.STORECD_CTRL).value,
      productCd: this.searchCondition.get(Spvw10231Constant.PRODUCTCD_CTRL).value,
      partnerCd: this.searchCondition.get(Spvw10231Constant.PARTNERCD_CTRL).value,
      storeInCharge: this.searchCondition.get(Spvw10231Constant.STORE_IN_CHARGE_CTRL).value,
      officeInputFlg: this.getReqStatus(),
      pageDto: this.getPageDto()
    }
    const subsc = this.httpBasic.searchSpvw10231(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpvw10231Search) {
    this.commonService.closeSpinner();
    if(response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if(response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });
      const subcription = dialogRef.afterClosed().subscribe(() => {
        subcription.unsubscribe();
      });
      return;
    }

    if(response.results.length === 0) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI100001]);
      return;
    }

    for(let i = 0; i < Spvw10231Constant.MAXITEMS; i ++){
      if(i >= response.results.length) break;
      this.items.push(response.results[i])
    }

    this.itemRecordCount = this.items.length ?? 0;
    this.setSearchResult(response);
    if(response.results.length > Spvw10231Constant.MAXITEMS) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle,this.message.message[MI200003].replace(/%1/g,Spvw10231Constant.MAXITEMS));
    }
    this.matPagenatorItem.pageIndex = 0;
    this.pageChanged();
  }

  setSearchResult(response: RspSpvw10231Search){
    this.searchResult.get(Spvw10231Constant.PRODUCT_REQ_QTY_CTRL).setValue(response.productRequestQty);
    this.searchResult.get(Spvw10231Constant.PRODUCT_ERR_QTY_CTRL).setValue(response.productErrorQty)
    this.searchResult.get(Spvw10231Constant.MISSING_PRODUCT_REQ_QTY_CTRL).setValue(response.missingProductRequestQty);
  }

  isKeyboardLocked() {
    return this.numberKeypadComponent.isKeyboardLocked() ?? false;
  }

  set10KeyPadTarget(maxCol: number = 13, stringMode: boolean = true) {
    this.numberKeypadComponent.setTargetForm(this.searchCondition.get(Spvw10231Constant.PRODUCTCD_CTRL) as FormControl);
    this.numberKeypadComponent.setMaxCol(maxCol);
    if(stringMode) {
      this.numberKeypadComponent.setStringMode();
    }
  }

  onScan(barcode: string) {
    let productCd = barcode;
    if(this.commonService.config.loginBarcode) {
      productCd = barcode.substring(this.commonService.config.loginBarcode.start, this.commonService.config.loginBarcode.end);
    }
    this.searchCondition.get(Spvw10231Constant.PRODUCTCD_CTRL).setValue(productCd);
  }

  openBarcodeDialog() {
    const dialogRef = this.commonService.dialog.open(BarcodeDialogComponent, {
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe((data) => {
      if(data != undefined && data != null) {
        this.searchCondition.get(Spvw10231Constant.PRODUCTCD_CTRL).setValue(data);
      }
    });
  }

  ngOnDestroy() {
    if(this.searchConditionSubscription) this.searchConditionSubscription.unsubscribe();
  }
}
