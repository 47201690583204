import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/service/common.service';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { Spmt10291Dto } from 'src/app/response/rsp-spmt10291-search';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { ReqSpmt10291GetDetail } from 'src/app/request/req-spmt10291-get-detail';
import { Spmt10291GetDetailDto, RspSpmt10291GetDetail } from 'src/app/response/rsp-spmt10291-get-detail';
import { ReqAccess } from 'src/app/request/req-access';
import { Req } from 'src/app/request/req';
import { MatPaginator } from '@angular/material/paginator';

interface IMIADetailDialogTableColumnDef extends TableColumnDef{
  numberFlg?: boolean;
}

class IMIADetailDialogConstant{
  public static readonly messageSearching = '検索中・・・';
  public static readonly EMPTY_STRING: string = '';
}

@Component({
  selector: 'app-spmt10291-detail-dialog',
  templateUrl: './spmt10291-detail-dialog.component.html',
  styleUrls: ['./spmt10291-detail-dialog.component.css']
})
export class Spmt10291DetailDialogComponent implements OnInit {

  access:ReqAccess | any = {
    ...this.commonService.loginUser,
    cmpnyCd:IMIADetailDialogConstant.EMPTY_STRING,
    lang: IMIADetailDialogConstant.EMPTY_STRING,
    storeCd: IMIADetailDialogConstant.EMPTY_STRING,
  };

  public columnIdFirst: string[] = ["productCd","standardNm","inStockQty","redBlackFlgNm","reqStatusNm","inOrderPriceExc","inPosCostExc","inSellPriceExc","outReqDate","outApproveDate","outCrtDate","outCancelDate","slipDtlComment"]
  public columnIdSecond: string[] = ["productNm","inOrderPriceExcAmt","inPosCostExcAmt","inSellPriceExcAmt","inApproveDate"]

  public columnDefsItemsFirst: IMIADetailDialogTableColumnDef[] = [
    {columnId: "productCd", header: "商品コード", width: 150, align: "left",rowspan: 1},
    {columnId: "standardNm", header: "規格", width: 120, align: "left",rowspan: 1},
    {columnId: "inStockQty", header: "入庫数量", width: 80, align: "right",rowspan: 1},
    {columnId: "redBlackFlgNm", header: "赤黒区分", width: 120, align: "left",rowspan: 2},
    {columnId: "reqStatusNm", header: "申請状態", width: 120, align: "left",rowspan: 2},
    {columnId: "inOrderPriceExc", header: "発注単価", width: 120, align: "right",rowspan: 1, numberFlg: true},
    {columnId: "inPosCostExc", header: "POS原価", width: 120, align: "right",rowspan: 1, numberFlg: true},
    {columnId: "inSellPriceExc", header: "売価", width: 120, align: "right",rowspan: 1, numberFlg: true},
    {columnId: "outReqDate", header: "出庫申請日", width: 120, align: "center",rowspan: 2},
    {columnId: "outApproveDate", header: "出庫訂正日", width: 120, align: "center",rowspan: 2},
    {columnId: "outCrtDate", header: "出庫承認日", width: 120, align: "center",rowspan: 1},
    {columnId: "outCancelDate", header: "出庫取消日", width: 120, align: "center",rowspan: 2},
    {columnId: "slipDtlComment", header: "詳細コメント", width: 120, align: "left",rowspan: 2},
  ];

  public columnDefsItemsSecond: IMIADetailDialogTableColumnDef[] = [
    {columnId: "productNm", header: "商品名称", width: 370, align: "left",rowspan: 1, colspan: 3},
    {columnId: "inOrderPriceExcAmt", header: "発注金額", width: 120, align: "right",rowspan: 1, colspan: 1, numberFlg: true},
    {columnId: "inPosCostExcAmt", header: "POS金額", width: 120, align: "right",rowspan: 1, colspan: 1, numberFlg: true},
    {columnId: "inSellPriceExcAmt", header: "売価金額", width: 120, align: "right",rowspan: 1, colspan: 1, numberFlg: true},
    {columnId: "inApproveDate", header: "入庫承認日", width: 120, align: "center",rowspan: 1, colspan: 1},
  ]

  public itemTableWidth: any;
  public itemsShow: Spmt10291GetDetailDto[] = [];
  public items: Spmt10291GetDetailDto[] = [];
  public itemRecordCount: number = 0;
  public slipNo: string;
  public outStoreCd: string;
  public outStoreNm: string;
  public inStoreCd: string;
  public inStoreNm: string;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<Spmt10291DetailDialogComponent>,
    public httpBasic: HttpBasicService,
    @Inject(MAT_DIALOG_DATA) public data: {row: Spmt10291Dto},
    public commonService: CommonService) { }

  ngOnInit() {
    this.calcTableWidth();
    this.fnGetAccessInfo();
  }

  calcTableWidth() {
    let width = 1
    for (let colDef of this.columnDefsItemsFirst) {
      width = width + colDef.width + 1 + 8;
    }
    this.itemTableWidth = {"width": "" + width + "px"};
  }

  fnGetAccessInfo(){
    let req: Req = {
      access: this.access
    };
    this.httpBasic.getUserInfo(req).subscribe((res) =>{
      if (this.httpBasic.handleAppError(res)) return;
      if (res.rows.length > 0) {
        this.access.cmpnyCd = res.rows[0].mainCmpnyCd;
        this.access.storeCd = res.rows[0].mainStoreCd;
        this.access.sysModeCd = res.rows[0].sysModeCd;
        this.access.lang = res.rows[0].mainLang;
        this.getDetailPrc();
      }
    })
  }

  getDetailPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, IMIADetailDialogConstant.messageSearching);
    const request: ReqSpmt10291GetDetail = {
      slipNo: this.data.row.slipNo,
      access: this.access
    }
    const subsc = this.httpBasic.getDetailSpmt10291(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpmt10291GetDetail) {
    this.commonService.closeSpinner();
    if(this.httpBasic.handleAppError(response)){
      return;
    }

    this.itemRecordCount = response.results.length ?? 0;
    response.results.forEach((item: Spmt10291GetDetailDto) => {
      this.items.push(item)
    })
    this.slipNo = this.items[0].slipNo;
    this.outStoreCd = this.items[0].outStoreCd;
    this.outStoreNm = this.items[0].outStoreNm;
    this.inStoreCd = this.items[0].inStoreCd;
    this.inStoreNm = this.items[0].inStoreNm;
    this.pageChanged();
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0){
      this.pageChanged();
    }
  }

  pageChanged() {
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.itemsShow.push(this.items[i]);
      count++;
    }
  }

  colStyle(item: Spmt10291GetDetailDto){
    if(item.redBlackFlg !== '01'){
      return 'text-color-red';
    }
    if(item.delFlg === '1'){
      return 'bg-gray-color';
    }
    return IMIADetailDialogConstant.EMPTY_STRING;
  }
}
