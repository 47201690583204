import { formatDate } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ME200012, MI100001, MI200003, MQ200010 } from 'src/app/common/const-message-id';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { ErrorNotificationDialogComponent } from 'src/app/dialog/error-notification-dialog/error-notification-dialog.component';
import { Spmt10291ConfirmDialogComponent } from 'src/app/dialog/spmt10291-confirm-dialog/spmt10291-confirm-dialog.component';
import { Spmt10291DetailDialogComponent } from 'src/app/dialog/spmt10291-detail-dialog/spmt10291-detail-dialog.component';
import { Req } from 'src/app/request/req';
import { ReqAccess } from 'src/app/request/req-access';
import { ReqGetStoreList } from 'src/app/request/req-get-store-list';
import { ReqPageDto } from 'src/app/request/req-page-dto';
import { ReqSpmt10291Exclusion } from 'src/app/request/req-spmt10291-exclusion';
import { ReqSpmt10291Init } from 'src/app/request/req-spmt10291-init';
import { ReqSpmt10291Search } from 'src/app/request/req-spmt10291-search';
import { Rsp } from 'src/app/response/rsp';
import { DropdownListOfUserDto } from 'src/app/response/rsp-get-dropdown-list-of-user';
import { GetStoreListDto, RspGetStoreList } from 'src/app/response/rsp-get-store-list';
import { DropdownListDto, RspSpmt10291Init } from 'src/app/response/rsp-spmt10291-init';
import { Spmt10291Dto, RspSpmt10291Search } from 'src/app/response/rsp-spmt10291-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MessageService } from 'src/app/service/message.service';

interface IMIATableColumnDef extends TableColumnDef{
  numberFlg?: boolean;
}

class ItemSelect {
  check: FormControl;
  result: Spmt10291Dto;
}
class IMIAConstant {
  public static readonly MAXITEMS: number = 1000;
  public static readonly REDBLACKFLG_RED: string = '02';
  public static readonly DELFLG_STATUS: string = '1';
  public static readonly CHECKSTTFLG: string = '1';
  public static readonly DATEFORMAT: string = 'yyyy-MM-dd';
  public static readonly EMPTY_STRING: string = '';
  public static readonly INSTORECDCTRL: string = 'inStoreCd';
  public static readonly OUTSTORECDCTRL: string = 'outStoreCd';
  public static readonly SLIPNOCTRL: string = 'slipNo';
  public static readonly REQSTATUSCTRL: string = 'reqStatus';
  public static readonly OUTREQUESTERCDCTRL: string = 'outRequesterCd';
  public static readonly OUTAPPROVERCDCTRL: string = 'outApproverCd';
  public static readonly INAPPROVERCDCTRL: string = 'inApproverCd';
  public static readonly SLIPCREATEDATEFROMCTRL: string = 'slipCreateDateFrom';
  public static readonly SLIPCREATEDATETOCTRL: string = 'slipCreateDateTo';
  public static readonly CHECKFLGSLIPNOCTRL: string = 'checkFlgSlipNo';
  public static readonly MESSAGESEARCHING: string = '検索中・・・';
  public static readonly PAGETITLE: string = '店間移動入庫承認';
  public static readonly REQ_STATUS_JP: string = '申請状態';
  public static readonly ZERO = 0;
  public static readonly LIMIT_RECORD = 1001;
}

interface CheckboxData extends DropdownListDto{
  isChecked?: boolean;
}

@Component({
  selector: "app-spmt10291",
  templateUrl: "./spmt10291.component.html",
  styleUrls: ["./spmt10291.component.css"],
})
export class Spmt10291Component implements OnInit, OnDestroy {
  access:ReqAccess | any = {
    ...this.commonService.loginUser,
    cmpnyCd: IMIAConstant.EMPTY_STRING,
    lang: IMIAConstant.EMPTY_STRING,
    storeCd: IMIAConstant.EMPTY_STRING,
  };
  public itemsShow: ItemSelect[] = [];
  public items: ItemSelect[] = [];
  public allChecked: boolean = false;
  public itemRecordCount: number = 0;
  private subcriptionYesNoDialog: Subscription;

  public columnId: string[] = ["checkStatusFlg","slipNo","outStoreCd","outStoreNm","reqStatusNm","totalInStockQty","totalInOrderPriceExc","slipCreateDate","outRequesterNm","outApproverNm","inApproverNm","slipComment","approverComment"]

  public columnDefsItems: IMIATableColumnDef[] = [
    {columnId: "checkStatusFlg", header: "選択", width: 40, align: "center",checkbox: true},
    {columnId: "slipNo", header: "伝票番号", width: 100, align: "left", editable: true},
    {columnId: "outStoreCd", header: "出庫店舗コード", width: 120, align: "left"},
    {columnId: "outStoreNm", header: "出庫店舗名称", width: 120, align: "left"},
    {columnId: "reqStatusNm", header: "申請状態", width: 100, align: "left"},
    {columnId: "totalInStockQty", header: "入庫数量合計", width: 120, align: "right", numberFlg: true},
    {columnId: "totalInOrderPriceExc", header: "入庫原価金額合計", width: 120, align: "right", numberFlg: true},
    {columnId: "slipCreateDate", header: "伝票作成日", width: 100, align: "center"},
    {columnId: "outRequesterNm", header: "出庫申請者", width: 100, align: "left"},
    {columnId: "outApproverNm", header: "出庫承認者", width: 100, align: "left"},
    {columnId: "inApproverNm", header: "入庫承認者", width: 100, align: "left"},
    {columnId: "slipComment", header: "出庫申請者コメント", width: 150, align: "left"},
    {columnId: "approverComment", header: "出庫承認者コメント", width: 150, align: "left"},
  ];

  public storeCdList: GetStoreListDto[] = [];
  public approverCdList: DropdownListOfUserDto[] = [];
  public reqStatusKbnList: CheckboxData[] = [];
  public paginatorMaxWidth: any;
  public itemTableWidth: any;
  public isDisableSrchBtn: boolean = false;
  public isDisableConfirmBtn: boolean = true;
  public searchCondition: FormGroup;
  private searchConditionSubscription: Subscription;
  public pickerDateBegin: MatDatepicker<null>;
  public pickerDateEnd: MatDatepicker<null>;
  @ViewChild("matPagenatorItem", {static:false}) matPagenatorItem: MatPaginator;
  @ViewChild(MatTable, { static: false }) matTable: MatTable<any>;
  @ViewChild('slipNoInput',{ static: true }) slipNoInput: ElementRef;
  constructor(
    public commonService: CommonService,
    private message: MessageService,
    public httpBasic: HttpBasicService,
    private fb: FormBuilder
    ) { }

  ngOnInit(): void {
    this.commonService.pageTitle = IMIAConstant.PAGETITLE;
    this.searchCondition = this.fb.group({
      inStoreCd: [IMIAConstant.EMPTY_STRING,Validators.required],
      outStoreCd: [IMIAConstant.EMPTY_STRING],
      slipNo: [IMIAConstant.EMPTY_STRING],
      reqStatus: this.fb.array([]),
      outRequesterCd: [IMIAConstant.EMPTY_STRING],
      outApproverCd: [IMIAConstant.EMPTY_STRING],
      inApproverCd: [IMIAConstant.EMPTY_STRING],
      slipCreateDateFrom: [],
      slipCreateDateTo: [],
      checkFlgSlipNo: [true]
    });
    this.fnGetAccessInfo();
    this.calcTableWidth();
    this.searchConditionSubscription = this.searchCondition.valueChanges.subscribe(() => {
      this.isDisableSrchBtn = !this.searchCondition.valid;
    })
  }
  fnGetAccessInfo(){
    let req: Req = {
      access: this.access
    };
    this.httpBasic.getUserInfo(req).subscribe((res) =>{
      if (this.httpBasic.handleAppError(res)) return;
      if (res.rows.length > 0) {
        this.access.cmpnyCd = res.rows[0].mainCmpnyCd;
        this.access.storeCd = res.rows[0].mainStoreCd;
        this.access.lang = res.rows[0].mainLang;
        this.access.sysModeCd = res.rows[0].sysModeCd;
        this.searchCondition.patchValue(this.searchCondition);
        this.getStoreList();
        this.getInitPrc();
      }
    })
  }

  getInitPrc(){
    this.commonService.openSpinner(this.commonService.pageTitle, IMIAConstant.MESSAGESEARCHING);
    let request: ReqSpmt10291Init = {
      access: this.access
    }
    let subsc = this.httpBasic.initSpmt10291Init(request).subscribe(
      result => {
        this.setResultInit(result);
        subsc.unsubscribe();
      },
      error => {
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  ngAfterViewInit(): void {
    if (this.slipNoInput) {
      this.slipNoInput.nativeElement.focus();
    }
  }

  getStoreList(){
    const request: ReqGetStoreList = {
      storeCd : IMIAConstant.EMPTY_STRING,
      userId : IMIAConstant.EMPTY_STRING,
      cmpyCd: this.access.cmpnyCd,
      sysModeCd: this.access.sysModeCd,
      access: this.access
    }
    const subsc = this.httpBasic.getStoreList(request).subscribe(
      result => {
        this.checkResultStoreList(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResultStoreList(response: RspGetStoreList){
    response.rows.forEach((item: GetStoreListDto) => {
      this.storeCdList.push(item)
    })
    this.searchCondition.get(IMIAConstant.INSTORECDCTRL).setValue(this.access.storeCd);
  }

  setResultInit(result: RspSpmt10291Init){
    this.commonService.closeSpinner();
    const reqStatus = this.searchCondition.get('reqStatus') as FormArray;
    result.dropDownList.forEach((item: DropdownListDto) => {
      this.reqStatusKbnList.push(item);
      reqStatus.push(new FormControl(false));
    });
    if (reqStatus.length > 0) {
      reqStatus.at(1).setValue(true);
    }
    result.userList.forEach((item: DropdownListOfUserDto) => {
      this.approverCdList.push(item);
    })
  }

  openModalConfirm() {
    scrollTo(0, 10);
    let data: Spmt10291Dto[] = [];
    this.itemsShow.forEach((item: ItemSelect) => {
      if(item.check.value)
        data.push(item.result)
    })

    const dialogRef = this.commonService.dialog.open(Spmt10291ConfirmDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        searchResult: data
      }
    });

    let subscription = dialogRef.afterClosed().subscribe((data:boolean) => {
      if(data === true){
        this.searchBtn();
      }
      subscription.unsubscribe();
    });
  }

  calcTableWidth() {
    let width = 1
    for (let colDef of this.columnDefsItems) {
      width = width + colDef.width + 1 + 8;
    }
    this.itemTableWidth = {"width": "" + width + "px"};
    this.paginatorMaxWidth = {"max-width": "" + width + "px"}
  }

  pageChangedItem() {
    if (this.itemsShow?.length > 0){
      this.pageChanged();
    }
  }

  clearSearchResult(){
    this.items = [];
    this.itemsShow = [];
    this.allChecked = false;
    this.itemRecordCount = 0;
    this.isDisableConfirmBtn = true;
    this.matPagenatorItem._changePageSize(25)
  }

  pageChanged() {
    this.isDisableConfirmBtn = true;
    this.allChecked = false;
    this.itemsShow = [];
    let count = 0;
    for (let i = this.matPagenatorItem.pageIndex * this.matPagenatorItem.pageSize; i < this.itemRecordCount && count < this.matPagenatorItem.pageSize; i++) {
      this.items[i].check.setValue(false);
      this.itemsShow.push(this.items[i]);
      count++;
    }
    let id = "item-table-box";
    document.getElementById(id)?.scrollTo(0, 0);
  }

  allCheck(value: boolean) {
    this.itemsShow.forEach((rec) => {
      if(rec.result.checkStatusFlg === IMIAConstant.CHECKSTTFLG) rec.check.setValue(value)
    });
    this.allChecked = value;
    this.confirmBtnChangeStatus();
  }

  updateAllChecked() {
    this.confirmBtnChangeStatus();
    this.isCheckedAll();
  }

  isCheckedAll(){
    this.allChecked = !this.itemsShow.some(i => i.result.checkStatusFlg === IMIAConstant.CHECKSTTFLG && i.check.value === false)
  }

  searchBtn() {
    this.commonService.openSpinner(this.commonService.pageTitle, IMIAConstant.MESSAGESEARCHING);
    this.searchPrc();
  }

  getDate(ctrlNm: string){
    if(this.searchCondition.get(ctrlNm).value === null) {
      return IMIAConstant.EMPTY_STRING;
    }
    return formatDate(this.searchCondition.get(ctrlNm).value, IMIAConstant.DATEFORMAT, 'en_US')
  }
  clearBtn(){
    if(this.itemsShow.length > 0){
      this.subcriptionYesNoDialog = this.commonService.openYesNoDialog(this.commonService.pageTitle, this.message.message[MQ200010]).subscribe(data => {
        if(data) {
          this.resetSearchCondition();
          this.clearSearchResult();
        }
        this.subcriptionYesNoDialog.unsubscribe();
      })
    } else {
      this.resetSearchCondition();
      this.clearSearchResult();
    }
  }
  resetSearchCondition() {
    this.searchCondition.reset({
      inStoreCd: this.access.storeCd,
      outStoreCd: IMIAConstant.EMPTY_STRING,
      outRequesterCd: IMIAConstant.EMPTY_STRING,
      outApproverCd: IMIAConstant.EMPTY_STRING,
      inApproverCd: IMIAConstant.EMPTY_STRING,
      slipNo: IMIAConstant.EMPTY_STRING,
      checkFlgSlipNo: true
    });
    const reqStatus = this.searchCondition.get('reqStatus') as FormArray;
    if (reqStatus && reqStatus.length > 0) {
      reqStatus.at(1).setValue(true);
    }
  }

  getReqStatus(){
    const arr = [];
    const reqStatus = this.searchCondition.get('reqStatus') as FormArray;
    reqStatus.controls.forEach((control: FormControl, index: number) => {
      if(control.value === true)
        arr.push(this.reqStatusKbnList[index].DATACD)
    })
    return arr.toString()
  }

  getPageDto():ReqPageDto {
    return {
      pageNum: IMIAConstant.ZERO,
      dispNum: IMIAConstant.LIMIT_RECORD
    }
  }

  searchPrc() {
    const reqStatus = this.getReqStatus();

    if (!reqStatus || reqStatus.length === 0) {
        this.commonService.closeSpinner();
        this.commonService.openErrorDialog(this.commonService.pageTitle, this.message.message[ME200012].replace('%1', IMIAConstant.REQ_STATUS_JP));
        this.isDisableSrchBtn = true;
        return;
    }
    const request: ReqSpmt10291Search = {
      inStoreCd: this.searchCondition.get(IMIAConstant.INSTORECDCTRL).value,
      outStoreCd: this.searchCondition.get(IMIAConstant.OUTSTORECDCTRL).value,
      slipCreateDateFrom: this.getDate(IMIAConstant.SLIPCREATEDATEFROMCTRL),
      slipCreateDateTo: this.getDate(IMIAConstant.SLIPCREATEDATETOCTRL),
      reqStatus: this.getReqStatus(),
      outRequesterCd: this.searchCondition.get(IMIAConstant.OUTREQUESTERCDCTRL).value,
      outApproverCd: this.searchCondition.get(IMIAConstant.OUTAPPROVERCDCTRL).value,
      inApproverCd: this.searchCondition.get(IMIAConstant.INAPPROVERCDCTRL).value,
      slipNo: this.searchCondition.get(IMIAConstant.SLIPNOCTRL).value,
      checkFlgSlipNo: this.searchCondition.get(IMIAConstant.CHECKFLGSLIPNOCTRL).value,
      access: this.access,
      pageDto: this.getPageDto()
    }
    this.clearSearchResult();
    const subsc = this.httpBasic.searchSpmt10291(request).subscribe(
      result => {
        this.checkResult(result);
        subsc.unsubscribe();
      },
      error => {
        this.commonService.closeSpinner();
        this.httpBasic.handleError(error);
        subsc.unsubscribe();
      }
    )
  }

  checkResult(response: RspSpmt10291Search) {
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });
      const subcription = dialogRef.afterClosed().subscribe(() => {
        this.searchBtn();
        subcription.unsubscribe();
      });
      return;
    }

    for(let i = 0; i < IMIAConstant.MAXITEMS; i ++){
      if(i >= response.results.length) break;
      let row: ItemSelect = {
        check: new FormControl(false),
        result: response.results[i]
      }
      this.items.push(row)
    }

    this.itemRecordCount = this.items.length ?? 0;
    if (response.results.length == 0) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle, this.message.message[MI100001])
      return;
    }
    if (response.results.length > IMIAConstant.MAXITEMS) {
      this.commonService.openNotificationDialog(this.commonService.pageTitle,this.message.message[MI200003].replace(/%1/g,IMIAConstant.MAXITEMS));
    }
    this.matPagenatorItem.pageIndex = 0;
    this.pageChanged();
  }

  // Get detail on click link
  onClickLink(row: ItemSelect){
    this.commonService.openSpinner(this.commonService.pageTitle, IMIAConstant.MESSAGESEARCHING);
    const request: ReqSpmt10291Exclusion = {
      SlipNo: row.result.slipNo,
      updDatetime: row.result.updDatetime,
      access: this.access
    }
      const subsc = this.httpBasic.checkExclusionSpmt10291(request).subscribe(
        result => {
          this.checkResultClickLink(result,row);
          subsc.unsubscribe();
        },
        error => {
          this.commonService.closeSpinner();
          this.httpBasic.handleError(error);
          subsc.unsubscribe();
        }
      )
  }

  checkResultClickLink(response: Rsp, row: ItemSelect){
    this.commonService.closeSpinner();
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      const dialogRef = this.commonService.dialog.open(ErrorNotificationDialogComponent, {
        data: {errorTitle: this.commonService.pageTitle, errorMessage: response.normalError[0].errMsg}
      });
      const subcription = dialogRef.afterClosed().subscribe(() => {
        this.searchBtn();
        subcription.unsubscribe();
      });
      return;
    }
    this.openModalDetail(row.result)
  }

  openModalDetail(row: Spmt10291Dto) {
    scrollTo(0, 10);
    this.commonService.dialog.open(Spmt10291DetailDialogComponent, {
      disableClose: true,
      data: {
        row
      }
    });
  }

  confirmBtnChangeStatus(){
    if(this.itemsShow.some(i => i.check.value === true)){
      this.isDisableConfirmBtn = false;
      return;
    }
    this.isDisableConfirmBtn = true
  }

  rowStyle(item: Spmt10291Dto, column: TableColumnDef){
    if(column.checkbox) {
      return IMIAConstant.EMPTY_STRING;
    }
    if(item.redBlackFlg === IMIAConstant.REDBLACKFLG_RED){
      return 'text-color-red'
    }
    if(item.delFlg === IMIAConstant.DELFLG_STATUS){
      return 'border-right-gray bg-gray'
    }
    return IMIAConstant.EMPTY_STRING;
  }
  ngOnDestroy() {
    if (this.searchConditionSubscription) this.searchConditionSubscription.unsubscribe();
  }
}
