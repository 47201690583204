/******************** MEXXXXXX(Error Messages) *********************/
export const ME000001 = "ME000001";
export const ME000002 = "ME000002";
export const ME000003 = "ME000003";
export const ME000004 = "ME000004";
export const ME000005 = "ME000005";

// 基幹システム defined message For MM
export const ME100001 = "ME100001";
export const ME100002 = "ME100002";
export const ME100003 = "ME100003";
export const ME100004 = "ME100004";
export const ME100005 = "ME100005";
export const ME100006 = "ME100006";
export const ME100007 = "ME100007";
export const ME100008 = "ME100008";
export const ME100009 = "ME100009";
export const ME100010 = "ME100010";
export const ME100011 = "ME100011";
export const ME100012 = "ME100012";
export const ME100013 = "ME100013";
export const ME100014 = "ME100014";
export const ME100015 = "ME100015";
export const ME100016 = "ME100016";
export const ME100017 = "ME100017";
export const ME100018 = "ME100018";
export const ME100019 = "ME100019";
export const ME100020 = "ME100020";
export const ME100021 = "ME100021"
export const ME100022 = "ME100022";
export const ME100023 = "ME100023";

// 基幹システム defined message For VN
export const ME200001 = "ME200001";
export const ME200002 = "ME200002";
export const ME200003 = "ME200003";
export const ME200004 = "ME200004";
export const ME200005 = "ME200005";
export const ME200007 = "ME200007";
export const ME200008 = "ME200008";
export const ME200011 = "ME200011";
export const ME200012 = "ME200012";
export const ME200013 = "ME200013";


/******************** MCXXXXXX(Confirmation Messages) **************/
export const MC000001 = "MC000001";
export const MC000002 = "MC000002";
export const MC000003 = "MC000003";
export const MC000004 = "MC000004";
export const MC000005 = "MC000005";
export const MC000006 = "MC000006";
export const MC000007 = "MC000007";
export const MC000008 = "MC000008";
export const MC000009 = "MC000009";
export const MC000010 = "MC000010";

/******************** MIXXXXXX(Information Messages) ***************/
export const MI100001 = "MI100001";
export const MI100002 = "MI100002";
export const MI100003 = "MI100003";
export const MI100004 = "MI100004";
export const MI100005 = "MI100005";

export const MI200001 = "MI200001";
export const MI200002 = "MI200002";
export const MI200003 = "MI200003";
export const MI200007 = "MI200007";
export const MI200008 = "MI200008";
export const MI200009 = "MI200009";
export const MI200010 = "MI200010";

/******************** MQXXXXXX(Question Messages) ******************/
export const MQ100001 = "MQ100001";

export const MQ200001 = "MQ200001";
export const MQ200002 = "MQ200002";
export const MQ200003 = "MQ200003";
export const MQ200006 = "MQ200006";
export const MQ200007 = "MQ200007";
export const MQ200008 = "MQ200008";
export const MQ200009 = "MQ200009";
export const MQ200010 = "MQ200010";
export const MQ200011 = "MQ200011";





/******************** MWXXXXXX(Warning Messages) *******************/


/******************** MSXXXXXX(System Messages) ********************/

