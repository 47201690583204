import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NumberKeypadComponent } from "src/app/partsCommon/number-keypad/number-keypad.component";
import { Spmt10311Dto } from "src/app/response/rsp-spmt10311-search";
import { CommonService } from "src/app/service/common.service";
import { DropdownListDto } from "src/app/response/rsp-spmt10311-init";
import { Subscription } from "rxjs";
import { ME100010, ME200004, ME200005 } from "src/app/common/const-message-id";
import { MessageService } from "src/app/service/message.service";

class Spmt10311DetailConstant {
  public static readonly STOCK_QTY_JP: string = '在庫数';
  public static readonly ERR_DTQTY_OUT_OF_RANGE = '';
  public static readonly QTY_JP: string = '数量';
  public static readonly EMPTY_STRING = '';
  public static readonly DTQTYCTRL = 'dtQtyCtrl';
  public static readonly DTLCOMMENTCTRL = 'dtlCommentCtrl';
  public static readonly SLIPKBNCTRL = 'slipKbnCtrl';
  public static readonly MIN_VALUE_OF_DTQTY_FPMT1031 = 1;
  public static readonly MIN_VALUE_OF_DTQTY_FPMT1036 = -99999;
  public static readonly MAX_VALUE_OF_DTQTY = 99999;
  public static readonly REQSTATUS_0 = '0';
  public static readonly BTN_NAME_ADD = '追加';
  public static readonly BTN_NAME_UPDATE = '更新';
}

export class DTRDetailDto{
  dtlCommentCtrl: FormControl;
  slipKbnCtrl: FormControl;
  dtQtyCtrl: FormControl;
  result: Spmt10311Dto;
}

@Component({
  selector: "app-spmt10311-detail-dialog",
  templateUrl: "./spmt10311-detail-dialog.component.html",
  styleUrls: ["./spmt10311-detail-dialog.component.css"],
})
export class Spmt10311DetailDialogComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public category: string;
  public isShowErrMsg: boolean = false;
  public isDisableConfirmBtn: boolean = false;
  public errMsg: string = Spmt10311DetailConstant.EMPTY_STRING;

  public slipKbnList: DropdownListDto[] = [];
  public btnName: string = Spmt10311DetailConstant.EMPTY_STRING;
  private subscription: Subscription;
  @ViewChild("keypad", {static: true}) numberKeypadComponent: NumberKeypadComponent;

  constructor(
    public dialogRef: MatDialogRef<Spmt10311DetailDialogComponent>,
    public commonService: CommonService,
    private message: MessageService,
    @Inject(MAT_DIALOG_DATA) public data: { slipKbnList: DropdownListDto[], row: Spmt10311Dto, isFPMT1031: boolean, isUpdate: boolean},
    private fb: FormBuilder,
  ) {
    this.category = `[${data.row.gMidClassCd2} - ${data.row.gMidClassNm}] - [${data.row.gMidClassCd2} - ${data.row.gSmallClassCd3} - ${data.row.gSmallClassNm}] - [${data.row.gMidClassCd2} - ${data.row.gSmallClassCd3} - ${data.row.gSubClassCd4} - ${data.row.gSubClassNm}]`;
  }

  ngOnInit() {
    this.btnName = this.data.isUpdate ? Spmt10311DetailConstant.BTN_NAME_UPDATE : Spmt10311DetailConstant.BTN_NAME_ADD;
    this.commonService.config.use10KeyPad = true;
    this.slipKbnList = this.data.slipKbnList;
    let isDisable: boolean = this.isDisableWhenInit();
    this.formGroup = this.fb.group({
      dtQtyCtrl: [
        {value: this.data.row.dtQty, disabled: this.data.isUpdate && isDisable},
        [Validators.required, this.integerValidator, Validators.min(this.data.isFPMT1031 ? Spmt10311DetailConstant.MIN_VALUE_OF_DTQTY_FPMT1031 : Spmt10311DetailConstant.MIN_VALUE_OF_DTQTY_FPMT1036), Validators.max(99999)]
      ],
      dtlCommentCtrl: [
        {value: this.data.row.slipDtlComment, disabled: this.data.isUpdate && isDisable},
        [Validators.maxLength(256)]
      ],
      slipKbnCtrl: [
        {value: this.data.row.slipKbn, disabled: !this.data.isFPMT1031 || isDisable},
        [Validators.required]
      ]
    });

    if(!this.data.isUpdate || !isDisable){
      this.numberKeypadComponent.setTargetForm(this.formGroup.get(Spmt10311DetailConstant.DTQTYCTRL) as FormControl);
      this.numberKeypadComponent.setMaxCol(6);
      this.numberKeypadComponent.setStringMode();
    }
    this.isDisableConfirmBtn = !this.formGroup.valid;
    this.dtQtyChange();
    this.formGroupChangeValue();
  }

  isDisableWhenInit(){
    if(this.data.isFPMT1031){
      return this.data.row.reqStatus !== Spmt10311DetailConstant.REQSTATUS_0 && this.data.row.reqStatus !== Spmt10311DetailConstant.EMPTY_STRING;
    }
    return this.data.row.reqStatus !== Spmt10311DetailConstant.EMPTY_STRING;
  }

  integerValidator(control: FormControl) {
    const pattern = /^-?\d+$/;
    return pattern.test(control.value) ? null : { 'integer': true };
  }

  formGroupChangeValue(){
    this.subscription = this.formGroup.valueChanges.subscribe(() => {
      this.dtQtyChange();
      this.isDisableConfirmBtn = !this.formGroup.valid;
    })
  }

  isKeyboardLocked() {
    if (this.numberKeypadComponent.isKeyboardLocked()) return true;
    return false;
  }

  is10KeyPadTarget(ctrlNm: string) {
    const control = this.formGroup.get(ctrlNm) as FormControl;
    if (control === this.numberKeypadComponent.inputField) return true;
    return false;
  }

  dtQtyChange(){
    let dtQty = this.formGroup.get(Spmt10311DetailConstant.DTQTYCTRL).value;
    let minValue = this.data.isFPMT1031 ? Spmt10311DetailConstant.MIN_VALUE_OF_DTQTY_FPMT1031 : Spmt10311DetailConstant.MIN_VALUE_OF_DTQTY_FPMT1036;

    if (dtQty === Spmt10311DetailConstant.EMPTY_STRING || dtQty === '-'){
      this.errMsg = this.message.message[ME100010];
      return true;
    }
    if(+dtQty < minValue || +dtQty > Spmt10311DetailConstant.MAX_VALUE_OF_DTQTY){
      this.errMsg = this.message.message[ME200005].replace('%1', Spmt10311DetailConstant.QTY_JP).replace('%2', ''+minValue).replace('%3', Spmt10311DetailConstant.MAX_VALUE_OF_DTQTY);
      return true;
    }
    if(this.data.isFPMT1031){
      if(+dtQty > +this.data.row.logicStockQty) {
        this.errMsg = this.message.message[ME200004].replace('%1', Spmt10311DetailConstant.QTY_JP).replace('%2', Spmt10311DetailConstant.STOCK_QTY_JP);
        return true;
      }
    } else {
      let dtQtyValue: number = +dtQty;
      if(dtQtyValue < 0 && Math.abs(dtQtyValue) > +this.data.row.logicStockQty) {
        this.errMsg = this.message.message[ME200004].replace('%1', Spmt10311DetailConstant.QTY_JP).replace('%2', Spmt10311DetailConstant.STOCK_QTY_JP);
        return true;
      }
    }

    this.errMsg = Spmt10311DetailConstant.EMPTY_STRING;
    this.data.row.totalPrice = `${(+this.data.row.sellPriceExc * +dtQty).toFixed(2)}`;
    this.data.row.totalOrderPrice = `${(+this.data.row.orderPriceExc * +dtQty).toFixed(2)}`;
    return false;
  }

  onAdd() {
    let isErr: boolean = this.dtQtyChange();
    if(isErr === false){
      this.dialogRef.close({
        result: this.data.row,
        dtQtyCtrl: this.formGroup.get(Spmt10311DetailConstant.DTQTYCTRL),
        dtlCommentCtrl: this.formGroup.get(Spmt10311DetailConstant.DTLCOMMENTCTRL),
        slipKbnCtrl: this.formGroup.get(Spmt10311DetailConstant.SLIPKBNCTRL)
      });
    }
  }

  ngOnDestroy() {
    if(this.subscription) this.subscription.unsubscribe();
  }
}
