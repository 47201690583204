import { formatDate } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TableColumnDef } from 'src/app/common/table-column-def';
import { SectionHeaderComponent } from 'src/app/partsCommon/section-header/section-header.component';
import { LoginStoreDto } from 'src/app/response/login-store-dto';
import { StoreDto } from 'src/app/response/rsp-inventory-schedule-list';
import { InvMonthListDto } from 'src/app/response/rsp-inventory-month-list';
import { InvMonthSearchDto } from 'src/app/request/req-inventory-month-list';
import { Spiv00061SearchDto, ReqSpiv00061Search } from 'src/app/request/req-spiv00061-search';
import { Spiv00061UpdateDto, ReqSpiv00061Update } from 'src/app/request/req-spiv00061-update';
import { RspSpiv00061Search, RspSpiv00061SearchDto } from 'src/app/response/rsp-spiv00061-search';
import { CommonService } from 'src/app/service/common.service';
import { HttpBasicService } from 'src/app/service/http-basic.service';
import { MessageService } from 'src/app/service/message.service';
import * as MessageID from 'src/app/common/const-message-id';

@Component({
  selector: 'app-spiv00061',
  templateUrl: './spiv00061.component.html',
  styleUrls: ['./spiv00061.component.css']
})

export class Spiv00061Component implements OnInit, OnDestroy {

  @ViewChild("sectionInputHeader", { static: true }) sectionInput: SectionHeaderComponent;
  @ViewChild("sectionDifferenceHeader", { static: true }) sectionDifference: SectionHeaderComponent;

  private subscriptionInit: Subscription;
  private subscriptionFormGroup: Subscription;
  private subscStoreList: Subscription;
  private subscMonth: Subscription;
  private subsSearch: Subscription;
  private subsUpdate: Subscription;

  public formGroup: FormGroup;
  public storeConfirmDisable : boolean = true;
  public storeConfirmDisableMessage;
  public CMPNYCD: any;
  public loginStoreCd: any;
  public stores : StoreDto[] = [];
  public inventoryMonths : InvMonthListDto[] = [];
  public inventoryDateDisable : boolean = true;
  public selectedExist : boolean = false;
  public messageMade : boolean = false;

  public inventoryList : any[] = [];

  public tableWidthSchedule1: any;
  public columnSchedule1Ids : string[] = [
    'IVSCHEDULEID',
    'INVENTORYPROCESSSTATUS',
    'IVBUSINESSHOUR',
    'IVPARTIAL'
  ];
  public tableWidthSchedule2: any;
  public columnSchedule2Ids : string[] = [
    'IVPLNDATE',
    'IVENTSTARTDATE',
    'ACTUALIVPLNDATE',
    'ACTUALIVDATE',
    'IVCREATEPLANDATE',
    'INVDIFFCREATEDATE',
    'STORECONFIRMPLNDATE',
    'STORECONFIRMDATE'
  ];

  public inputList : any[] = [];
  public tableWidthInput: any;
  public columnInputIds : string[] = [
    'IVPLNNUMBER',
    'INPUTCNT',
    'MINUSCNT',
    'ERRORCNT'
  ];

  public differenceList : any[] = [];
  public tableWidthDifference: any;
  public columnDifferenceIds : string[] = [
    'UNCOUNT',
    'UNCONFCOUNT',
    'DIFFCNT',
    'DIFFCONFCNT',
  ];
  public originalDifferenceIds : string[] = [
    'UNCOUNT',
    'UNCONFCOUNT',
    'DIFFCNT',
    'DIFFCONFCNT',
  ];
  public additionalDifferenceIds : string[] = [
    'IVINVESTIGATECONDITION',
    'ININVESTIGATETHRESHOLD',
    'IVDIFFREQFLGCNT',
    'IVDIFFALLFLGCNT',
  ];

  public displayColumnIds: string[];
  public displayColumnDefs: TableColumnDef[];

  public columnSchedule1: TableColumnDef[] = [
    { columnId: 'IVSCHEDULEID', header: "棚卸日程番号", width: 100 },
    { columnId: 'INVENTORYPROCESSSTATUS', header: "状態", width: 100 },
    { columnId: 'IVBUSINESSHOUR', header: "営業中棚卸", width: 100, align: "center" },
    { columnId: 'IVPARTIAL', header: "部分棚卸", width: 100, align: "center" }
  ];
  public columnSchedule2: TableColumnDef[] = [
    { columnId: 'IVPLNDATE', header: "棚卸入力開始予定日", width: 100, align: "center" },
    { columnId: 'IVENTSTARTDATE', header: "棚卸入力開始日", width: 100, align: "center" },
    { columnId: 'ACTUALIVPLNDATE', header: "実地棚卸予定日", width: 100, align: "center" },
    { columnId: 'ACTUALIVDATE', header: "実地棚卸日", width: 100, align: "center" },
    { columnId: 'IVCREATEPLANDATE', header: "棚卸差異作成予定日", width: 100, align: "center" },
    { columnId: 'INVDIFFCREATEDATE', header: "棚卸差異作成日", width: 100, align: "center" },
    { columnId: 'STORECONFIRMPLNDATE', header: "店舗確定予定日", width: 100, align: "center" },
    { columnId: 'STORECONFIRMDATE', header: "店舗確定日", width: 100, align: "center" }
  ];
  public columnInput: TableColumnDef[] = [
    { columnId: 'IVPLNNUMBER', header: "棚卸入力予定アイテム数", width: 150 },
    { columnId: 'INPUTCNT', header: "棚卸入力アイテム数", width: 150 },
    { columnId: 'MINUSCNT', header: "棚卸マイナスアイテム数", width: 150 },
    { columnId: 'ERRORCNT', header: "棚卸エラーアイテム数", width: 150 }
  ];
  public columnDifference: TableColumnDef[] = [
    { columnId: 'UNCOUNT', header: "未棚卸アイテム数", width: 150 },
    { columnId: 'UNCONFCOUNT', header: "未棚卸確認済アイテム数", width: 150 },
    { columnId: 'DIFFCNT', header: "棚卸差異アイテム数", width: 150 },
    { columnId: 'DIFFCONFCNT', header: "棚卸差異確認済アイテム数", width: 150 },
  ];
  public originalDifference: TableColumnDef[] = [
    { columnId: 'UNCOUNT', header: "未棚卸アイテム数", width: 150 },
    { columnId: 'UNCONFCOUNT', header: "未棚卸確認済アイテム数", width: 150 },
    { columnId: 'DIFFCNT', header: "棚卸差異アイテム数", width: 150 },
    { columnId: 'DIFFCONFCNT', header: "棚卸差異確認済アイテム数", width: 150 },
  ];
  public additionalDifference: TableColumnDef[] = [
    { columnId: 'IVINVESTIGATECONDITION', header: "棚卸差異要調査条件項目", width: 160 },
    { columnId: 'ININVESTIGATETHRESHOLD', header: "棚卸差異要調査条閾値", width: 160 },
    { columnId: 'IVDIFFREQFLGCNT', header: "棚卸差異要調査アイテム数", width: 160 },
    { columnId: 'IVDIFFALLFLGCNT', header: "棚卸差異要調査確認済アイテム数", width: 180 }
  ];

  constructor(
    public commonService: CommonService,
    private fb: FormBuilder,
    private httpBasic: HttpBasicService,
    private messageEntity: MessageService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.loginStoreCd = this.commonService.loginUser.realStoreCd;
    this.commonService.pageTitle = this.commonService.pageMenuName;
    this.initFormGroup();
    this.calcTableWidthSchedule1();
    this.calcTableWidthSchedule2();
    this.calcTableWidthInput();
    this.calcTableWidthDifference();
    this.initLists();
  }

  async initLists(): Promise<void> {
    try {
      await this.getStoreLists();
      await this.getInvMonthLists(this.loginStoreCd);
      this.commonService.closeSpinner();
      this.searchInventory();
    } catch (error) {
      this.commonService.closeSpinner();
      this.httpBasic.handleError(error);
    }
  }

  getStoreLists(): Promise<void>{
    return new Promise((resolve, reject) => {
      var request: InvMonthSearchDto = {
        storeCd: this.loginStoreCd,
        access: { ...this.commonService.loginUser },
      };
      
      this.subscStoreList = this.httpBasic.generalRequest("GetStoreList", request).subscribe(
        response => {
          this.receiveStoreList(response);
          this.subscStoreList.unsubscribe();
          this.subscStoreList = undefined;
          resolve();
        },
        error => {//clearProgressState
          this.commonService.closeSpinner();
          this.subscStoreList.unsubscribe();
          this.subscStoreList = undefined;
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveStoreList(response) {
    if (this.httpBasic.handleAppError(response)) return;

    this.stores = [];
    if (response.rows.length > 0) {
      this.formGroup.get("storeCd").setValue(this.loginStoreCd);
      this.stores = response.rows;
    }
    let index = this.stores.findIndex(store => store.storeCd === this.formGroup.get("storeCd").value);
    if(index !== -1){
      this.CMPNYCD = this.stores[index].cmpyCd;
    }
    if (this.stores.length === 1) {
      this.formGroup.get("storeCd").disable();
    } else {
      this.formGroup.get("storeCd").enable();
    }
  }

  getInvMonthLists(storeCd: string): Promise<void>{
    return new Promise((resolve, reject) => {
      var request: InvMonthSearchDto = {
        storeCd: storeCd,
        cmpyCd: this.CMPNYCD,
        order: "1",
        access: { ...this.commonService.loginUser },
      };
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.subscMonth = this.httpBasic.generalRequest("GetInvMonthList", request).subscribe(
        response => {
          this.subscMonth.unsubscribe();
          this.subscMonth = undefined;
          this.receiveMonthList(response);
          this.commonService.closeSpinner();
          resolve();
        },
        error => {
          this.commonService.closeSpinner();
          this.subscMonth.unsubscribe();
          this.subscMonth = undefined;
          this.httpBasic.handleError(error);
          reject(error);
        }
      );
    });
  }

  receiveMonthList(response) {
    if (this.httpBasic.handleAppError(response)) return;
    this.inventoryMonths = [];
    if (response.rows.length > 0) {
      // this.formGroup.get("inventoryDate").setValue(response.rows[0].invScheduleId);
      this.inventoryMonths = response.rows;
    }
    this.selectedExist = false;
    if(response.selected.length > 0){
      this.formGroup.get("invScheduleId").setValue(response.selected[0].invScheduleId);
      this.formGroup.get("invMonth").setValue(response.selected[0].invMonth);
      this.formGroup.get("inventoryDate").setValue(response.selected[0].invPeriod);
      if(response.selected[0].invScheduleId != null){
        this.selectedExist = true;
      }
    }
  }

  ngOnDestroy() {
    if (this.subscriptionFormGroup) this.subscriptionFormGroup.unsubscribe();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
  }

  async storeFocusOut(){
    await this.getInvMonthLists(this.formGroup.get('storeCd').value);
    if(this.inventoryMonths.length > 0 && this.selectedExist){
      await this.searchInventory();
    }
    this.commonService.closeSpinner();
  }

  searchInventory(){
    var request: ReqSpiv00061Search = {
      access: { ...this.commonService.loginUser },
      spiv00061Search : {
        storeCdFv: this.formGroup.get("storeCd").value,
        inventoryDateFv: this.formGroup.get("invScheduleId").value
      }
    }
    this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
    this.subsSearch = this.httpBasic.generalRequest("Spiv00061Search",request).subscribe(
      result => {
        this.subsSearch.unsubscribe();
        this.subsSearch = undefined;
        this.checkResult(result, true);
        this.cdr.detectChanges();
      },
      error => {
        this.subsSearch.unsubscribe();
        this.subsSearch = undefined;
        this.httpBasic.handleError(error);
        this.commonService.closeSpinner();
        this.cdr.detectChanges();
      }
    );
  };

  initFormGroup() {
    // this.stores = this.commonService.allStores;
    this.formGroup = this.fb.group({
      storeCd: [this.commonService.loginUser.realStoreCd],
      inventoryDate: [""],
      invScheduleId: [""],
      invMonth: [""]
    });
  }

  doUpdate() {
    if(!this.storeConfirmDisable){
      var request: ReqSpiv00061Update = {
        access: { ...this.commonService.loginUser },
        spiv00061Update : {
          IVSCHEDULEID: this.inventoryList.map(item => item.IVSCHEDULEID)
        }
      }
      this.commonService.openSpinner(this.commonService.pageTitle, "検索中・・・");
      this.subsUpdate = this.httpBasic.generalRequest("Spiv00061Update",request).subscribe(
        result => {
          this.subsUpdate.unsubscribe();
          this.subsUpdate = undefined;
          this.checkResult(result, false);
          this.searchInventory();
          // this.cdr.detectChanges();
        },
        error => {
          this.httpBasic.handleError(error);
        }
      );
    }
  }

  checkResult(response: RspSpiv00061Search, resultExist: boolean) {
    this.clearProgressState();
    this.storeConfirmDisable = false;
    if (response.fatalError && response.fatalError.length > 0) {
      this.commonService.openFatalErrorDialog(this.commonService.pageTitle, response.fatalError[0].errMsg);
      return;
    }
    if (response.normalError && response.normalError.length > 0) {
      this.commonService.openErrorDialog(this.commonService.pageTitle, response.normalError[0].errMsg);
      return;
    }
    if(resultExist){
      this.inventoryList = [];
      this.sectionInput.openSectionBody();
      this.sectionDifference.openSectionBody();
      let data = response.result;
      let extraColumn = false;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        element.IVPARTIAL = element.IVPARTIAL;
        element.IVBUSINESSHOUR = element.IVBUSINESSHOUR;
        if(['差異数量','差異原価金額','差異売価金額'].includes(element.IVINVESTIGATECONDITION)){
          extraColumn = true;
        }
        this.inventoryList.push({
          IVSCHEDULEID : element.IVSCHEDULEID ,
          INVENTORYPROCESSSTATUS : element.INVENTORYPROCESSSTATUS ,
          IVPARTIAL : element.IVPARTIAL ,
          IVBUSINESSHOUR : element.IVBUSINESSHOUR ,
          IVPLNDATE : element.IVPLNDATE ,
          IVENTSTARTDATE : element.IVENTSTARTDATE ,
          ACTUALIVPLNDATE : element.ACTUALIVPLNDATE ,
          ACTUALIVDATE : element.ACTUALIVDATE ,
          IVCREATEPLANDATE : element.IVCREATEPLANDATE ,
          INVDIFFCREATEDATE : element.INVDIFFCREATEDATE ,
          STORECONFIRMPLNDATE : element.STORECONFIRMPLNDATE ,
          STORECONFIRMDATE : element.STORECONFIRMDATE ,
          IVINESTIGATECONDITION : element.IVINESTIGATECONDITION ,
          IVPLNNUMBER : element.IVPLNNUMBER ,
          INPUTCNT : element.INPUTCNT ,
          MINUSCNT : element.MINUSCNT ,
          ERRORCNT : element.ERRORCNT ,
          UNCOUNT : element.UNCOUNT ,
          UNCONFCOUNT : element.UNCONFCOUNT ,
          DIFFCNT : element.DIFFCNT ,
          DIFFCONFCNT : element.DIFFCONFCNT,
          IVINVESTIGATECONDITION: element.IVINVESTIGATECONDITION,
          ININVESTIGATETHRESHOLD: element.ININVESTIGATETHRESHOLD,
          IVDIFFREQFLGCNT: element.IVDIFFREQFLGCNT,
          IVDIFFALLFLGCNT: element.IVDIFFALLFLGCNT,
        });
      }
      if(extraColumn){
        this.columnDifference = [...this.originalDifference, ...this.additionalDifference];
        this.columnDifferenceIds = [...this.originalDifferenceIds, ...this.additionalDifferenceIds];
        this.calcTableWidthDifference();
      }else{
        this.columnDifference = this.originalDifference;
        this.columnDifferenceIds = this.originalDifferenceIds;
        this.calcTableWidthDifference();
      }
      this.messageMade = false;
    }
  }

  clearProgressState() {
    this.commonService.closeSpinner();
    if (this.subscriptionInit) this.subscriptionInit.unsubscribe();
    this.subscriptionInit = undefined;
  }

  calcTableWidthSchedule1() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 訂正ボタン
    for (var col of this.columnSchedule1) {
      width = width + col.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidthSchedule1 = { "width": "" + width + "px" };
  }

  calcTableWidthSchedule2() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 訂正ボタン
    for (var col of this.columnSchedule2) {
      width = width + col.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidthSchedule2 = { "width": "" + width + "px" };
  }

  calcTableWidthInput() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 訂正ボタン
    for (var col of this.columnInput) {
      width = width + col.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidthInput = { "width": "" + width + "px" };
  }

  calcTableWidthDifference() {
    var width = 1;                // For left border
    width = width + 80 + 1 + 8;   // 訂正ボタン
    for (var col of this.columnDifference) {
      width = width + col.width + 1 + 8;    // 1 for right border, 8 for padding
    }
    this.tableWidthDifference = { "width": "" + width + "px" };
  }

  styleForSchedule1(id: string, item: string[]) {
    return this.styleForBody(this.columnSchedule1, id, item);
  }

  styleForSchedule2(id: string, item: string[]) {
    return this.styleForBody(this.columnSchedule2, id, item);
  }

  styleForInput(id: string, item: string[]) {
    return this.styleForBody(this.columnInput, id, item);
  }

  styleForDifference(id: string) {
    return this.styleForBody(this.columnDifference, id);
  }

  styleForBody(colDefs: TableColumnDef[], id: string, item?: any) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        let highlight : boolean = false;
        if(item){
          if(colDef.columnId == "INVENTORYPROCESSSTATUS" && ["棚卸開始前","店舗確定済","本部確定済"].includes(item[id])){
            highlight = true;
            if(!this.messageMade){
              this.messageMade = true;
              this.storeConfirmDisable = true;
              if(item[id] == "棚卸開始前"){
                this.storeConfirmDisableMessage = this.messageEntity.message[MessageID.ME100003];
              }else if(item[id] == "店舗確定済"){
                this.storeConfirmDisableMessage = this.messageEntity.message[MessageID.ME100004];
              }else if(item[id] == "本部確定済"){
                this.storeConfirmDisableMessage = this.messageEntity.message[MessageID.ME100005];
              }
            }
          }else if(colDef.columnId == "INVDIFFCREATEDATE" && (item[id] == null || item[id] == "")){
            highlight = true;
            this.storeConfirmDisable = true;
            if(!this.messageMade){
              this.messageMade = true;
              this.storeConfirmDisableMessage = this.messageEntity.message[MessageID.ME100002];
            }
          }else if (colDef.columnId == "MINUSCNT" && (item[id] != null && item[id] !== "" && parseInt(item[id]) > 0)) {
            highlight = true;
            this.storeConfirmDisable = true;
            if(!this.messageMade){
              this.messageMade = true;
              this.storeConfirmDisableMessage = this.messageEntity.message[MessageID.ME100001];
            }
          }
        }
        return {
          "background-color": highlight ? "red" : "",
          "width": "" + colDef.width + "px",
          "text-align": colDef.align ? colDef.align : "left"
        }
      }
    }
  }

  styleForHeaderSchedule1(id: string) {
    return this.styleForHeaderBody(this.columnSchedule1, id);
  }

  styleForHeaderSchedule2(id: string) {
    return this.styleForHeaderBody(this.columnSchedule2, id);
  }

  styleForHeaderInput(id: string) {
    return this.styleForHeaderBody(this.columnInput, id);
  }

  styleForHeaderDifference(id: string) {
    return this.styleForHeaderBody(this.columnDifference, id);
  }

  styleForHeaderBody(colDefs: TableColumnDef[], id: string) {
    for (var colDef of colDefs) {
      if (colDef.columnId === id) {
        return {
          "width": "" + colDef.width + "px"
        }
      }
    }
  }
}
