import { Req } from './req';
import { Rsp } from '../response/rsp';
import { FormControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

export interface ReqSpiv00111 extends Req {
  ivInputId: string;
}
export interface RspSpiv00111 extends Rsp {
  rows: Spiv00111List[];
}

export interface Spiv00111Dto {
  storeCd: string;
  productCd: string;
  invInputId: string;
  invInputDetailId: string;
  inventoryType: string;
  inventoryTypeNm: string;
  inventoryQty: number;
  posPrice: any;
  posPriceAmt: any;
  salePrice: any;
  salePriceAmt: any;
  invDatetime: string;
  createdAuthor: string;
  createdAuthorNm: string;
  editable: boolean;
  inputable: boolean;
}
export interface ReqStoreProduct extends Req {
  cmpnyCd: string;
  storeCd: string;
  productCd: string;
}
export interface RspStoreProduct extends Rsp {
  rows: Spiv00111List[];
}
export class Spiv00111List {
  public invEntryDetailForm: FormControl = new FormControl("");
  public storeCd: string = "";
  public productCd: string = "";
  public invInputId: string = "";
  public invInputDetailId: string = "";
  public inventoryType: string = "";
  public inventoryTypeNm: string = "";
  public inventoryQty: number = 0;
  public posPrice: any = 0.00;
  public posPriceAmt: any = 0.00;
  public salePrice: any = 0.00;
  public salePriceAmt: any = 0.00;
  public posPriceDisplay: any = "";
  public salePriceDisplay: any = "";
  public posPriceAmtDisplay: any = "";
  public salePriceAmtDisplay: any = "";
  public invDatetime: string = "";
  public createdAuthor: string = "";
  public createdAuthorNm: string = "";
  public editable: boolean = false;
  public inputable: boolean = false;
  public required: boolean = false;
  public originDto: Spiv00111Dto;

  constructor(dto?: Spiv00111Dto, private decimalPipe?: DecimalPipe) {
    if (dto)
      this.patchValue(dto);
  }

  patchValue(obj): void {
    for (let key in obj) {
      if (Object.keys(this).find((recKey) => recKey === key)) {
        this[key] = obj[key];
      }
    }
    this.invEntryDetailForm.setValue(this.inventoryQty);
    this.posPriceAmt = Number.isNaN(parseInt(this.invEntryDetailForm.value)) ? 0 : parseInt(this.invEntryDetailForm.value) * parseFloat(this.posPrice);
    this.salePriceAmt = Number.isNaN(parseInt(this.invEntryDetailForm.value)) ? 0 : parseInt(this.invEntryDetailForm.value) * parseFloat(this.salePrice);

    this.posPriceDisplay = this.formatDecimal(this.posPrice);
    this.salePriceDisplay = this.formatDecimal(this.salePrice);
    this.posPriceAmtDisplay = this.formatDecimal(this.posPriceAmt);
    this.salePriceAmtDisplay = this.formatDecimal(this.salePriceAmt);

    this.originDto = { ...obj };
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.0-0'); // Format with thousand separators and no decimal places
  }
  formatDecimal(value: number): string {
    return this.decimalPipe.transform(value, '1.2-2'); // Format with thousand separators and two decimal places
  }

  isEdited(key: string) {
    if (key == 'inventoryQty') {
      if (this.inventoryQty > 0) return true;
      if (this.invEntryDetailForm.value !== this.inventoryQty) return true;
      return false
    }
    return false;
  }
}

export interface Spiv00051HeaderDto {
  ivscheduleId: string;
  inventoryMonth: string;
  storeCd: string;
  gmainClassCd: string;
  gmidClassCd: string;
  gsmallClassCd: string;
  gsubClassCd: string;
  productCd: string;
  inventoryCnt: number;
  costPrice: any;
  sellPrice: any;
  partnerCd: string;
}
export interface ReqSpiv00111Confirm extends Req {
  updateItems: Spiv00111Dto[];
  insertItems: Spiv00111Dto[];
  insertHeaderItems: Spiv00051HeaderDto[],
  masterItems: Spiv00051HeaderDto[],
  cmpnyCd: string;
  invInputId: string;
  updatedtime: string;
}

export interface RspSpiv00111Confirm extends Rsp {
  invInputId: string;
  updatedtime: string;
}
